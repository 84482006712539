import React, { useState, useEffect, useRef, useCallback } from 'react';
import Moment from 'moment';
import Header from '../../layout/header';
import Footer from '../../layout/footer';
import Totop from '../../components/toTop';

import { Link } from "react-tiger-transition";
import GetService from "../../components/services_member/get.service";

import ModalLogin from "../../layout/modal/modalLogin";
import ModalRegister from "../../layout/modal/modalRegister";

import topic from '../../assets/img/topic.svg';
import sorn from '../../assets/img/sorn-bottom.svg';
import bg from '../../assets/img/banner_stem.jpg';
import ln1 from '../../assets/img/learn1.jpg';


const Stemlearning = () => {

    const user = JSON.parse(localStorage.getItem("member"));

    // if (!user) {
    //     window.location.href = "/programmes/stem_learning";
    //     console.log('no login')
    // } else {
    //     console.log('login')
    // }

    const ref = useRef(null);
    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: "smooth" });
    };
    // console.log(ref)

    const [datapage, setDatapage] = useState();
    const [dataconfig, setDataconfig] = useState();
    const [datasetting, setDatasetting] = useState();

    const fetchData = useCallback(async () => {

        GetService.getLearning().then(
            (response) => {
                if (response.status === 200) {
                    setDatapage(response.data.data)
                    setDataconfig(response.data.config)
                }
            }, (error) => {
                // window.location.reload();
            }
        )

        GetService.getPagesetting(4).then(
            (response) => {
                if (response.status === 200) {
                    setDatasetting(response.data.data)
                }
            }, (error) => {
                // window.location.reload();
            }
        )
    });
    useEffect(() => {
        fetchData()
    }, []);

    const [modalLogin, setModalLogin] = useState({ view: false });
    const [modalRegister, setModalRegister] = useState({ view: false });

    const onHideLogin = () => {
        setModalLogin({ view: false })
        setModalRegister({ view: true })
    }

    const onHideRegister = () => {
        setModalRegister({ view: false })
        setModalLogin({ view: true })
    }

    return (
        <>
            <div id="outer-container" ref={ref}>
                <Header page="programmes" />
                <div className="wrapper bg-white box-index">
                    <section className="" id="page-wrap">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 position-relative">

                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12 text-start">
                                                <div className='py-3'>
                                                    <Link to='/' transition='default-fade' className="font-12 a-default">Home</Link>
                                                    <span className='mx-2 font-12'>|</span>
                                                    <span className='font-12'>{datasetting ? datasetting.topic : null}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='py-5'>
                                        <div>
                                            <img src={topic} className="me-3 mb-2" style={{ width: "10px", transform: "rotate(90deg)" }} />
                                        </div>
                                        <div className='weight-600 font-22'>
                                            {datasetting ? datasetting.topic : null}
                                        </div>
                                        <div>{datasetting ? datasetting.sub_title : null}</div>
                                    </div>
                                    <div className="w-100 position-relative">
                                        {datasetting ? <>
                                            <img src={process.env.REACT_APP_BASE_URL_IMG + '/uploads/pages/' + datasetting.id + "/" + datasetting.banner} className="w-100 d-none d-md-block" />
                                            <img src={process.env.REACT_APP_BASE_URL_IMG + '/uploads/pages/' + datasetting.id + "/" + datasetting.banner_m} className="w-100 d-block d-md-none" />
                                        </> : null}
                                    </div>

                                    <div className="container">
                                        <div className="row justify-content-center">
                                            <div className="col-12 col-md-12 col-lg-12 text-start">
                                                <div className='pt-5 text-center'>
                                                    <div dangerouslySetInnerHTML={{ __html: datasetting ? datasetting.des : null }} />
                                                </div>

                                                {user ?
                                                    null
                                                    :
                                                    <div className='text-center pt-5'>
                                                        <a onClick={() => setModalLogin({ view: true })} className="btn-apply-border me-3 cursor-pointer d-inline-block">Login</a>
                                                        <a onClick={() => setModalRegister({ view: true })} className="btn-apply-border cursor-pointer d-inline-block">Register</a>
                                                    </div>}
                                                {/* <div className='text-center mt-5 pt-5'>
                                                    <img src={sorn} />
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="position-relative" id="page-wrap" style={{ background: "#F0F0F0", marginTop: "100px" }}>
                        <div style={{ height: "200px", background: "#fff" }}></div>
                        <div className="container" style={{ marginTop: "-200px" }}>
                            <div className="row mt-4">
                                {datapage ? datapage.map((val, index) => (
                                    <div className="col-12 col-md-4 col-lg-4 text-start mb-5" key={index}>
                                        {user ?
                                            <Link to={"/programmes/learning_detail/" + val.id} transition='default-fade' className="btn-box d-block">
                                                <div className='position-relative'>
                                                    <img src={process.env.REACT_APP_BASE_URL_IMG + "/uploads/learning/" + val.id + '/' + val.img} className="w-100" />
                                                </div>
                                                <div className='px-4 box-hover-blue'>
                                                    {/* <div className='pt-3 color-gold'>{val.title}</div> */}
                                                    <div className='weight-700 font-22 pt-3'>
                                                        {val.topic}
                                                    </div>
                                                    <div className='pt-2 font-14'>
                                                        {val.detail}
                                                    </div>
                                                    <div className="font-12 pt-2 pb-4 color-gray">{Moment(val.date_active).format('DD/MM/yyyy')}</div>

                                                    <div className='pb-5 pt-4'>
                                                        <span className="btn-learn-border">More Details</span>
                                                    </div>

                                                </div>
                                            </Link>
                                            :
                                            <a onClick={() => setModalLogin({ view: true })} className="btn-box d-block cursor-pointer">
                                                <div className='position-relative'>
                                                    <img src={process.env.REACT_APP_BASE_URL_IMG + "/uploads/learning/" + val.id + '/' + val.img} className="w-100" />
                                                </div>
                                                <div className='px-4 box-hover-blue'>
                                                    <div className='pt-3 color-gold'>{val.title}</div>
                                                    <div className='weight-700 font-22 '>
                                                        {val.topic}
                                                    </div>
                                                    <div className='pt-2 font-14'>
                                                        {val.detail}
                                                    </div>
                                                    <div className="font-12 pt-2 pb-4 color-gray">{Moment(val.date_active).format('DD/MM/yyyy')}</div>

                                                    <div className='pb-5 pt-4'>
                                                        <span className="btn-learn-border">More Details</span>
                                                    </div>

                                                </div>
                                            </a>
                                        }
                                    </div>
                                )) : <div className='col-12 p-5'>-- No Data --</div>}
                            </div>

                        </div>
                    </section>
                    <Totop page="programmes" handleClick={(e) => handleClick(e)} />
                    <Footer />
                </div>
            </div >
            {modalLogin.view ?
                <ModalLogin show={modalLogin.view} onHide={() => setModalLogin({ view: false })} onHideadd={() => onHideLogin()} />
                : null}

            {modalRegister.view ?
                <ModalRegister show={modalRegister.view} onHide={() => setModalRegister({ view: false })} onHideadd={() => onHideRegister()} />
                : null}
        </>
    );
};

export default Stemlearning;