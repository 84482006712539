import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

// import iconfb from '../assets/img/icon-fb.svg';
// import iconig from '../assets/img/icon-ig.svg';
// import iconyoutube from '../assets/img/icon-youtube.svg';
// import icontiktok from '../assets/img/icon-tiktok.svg';
// import btnline from '../assets/img/button-line.svg';
import fb from '../assets/img/Facebook-W.svg';
import tw from '../assets/img/Twitter-W.svg';
import yt from '../assets/img/Youtube-W.svg';
import ig from '../assets/img/Instagram-W.svg';
import inn from '../assets/img/In-W.svg';
import logofoot from '../assets/img/logo.svg';
import { Form, InputGroup, Button, Dropdown, DropdownButton } from 'react-bootstrap';

import { Link } from "react-tiger-transition";

import ModalService from "../layout/modal/modalService";
import ModalRegister from "../layout/modal/modalRegister";

const Footer = (data) => {

    let history = useHistory();

    // const { t } = useTranslation();
    const url = window.location.pathname;

    // const [menuactive, setMenuactive] = useState(false);

    const [memenu, setMemenu] = useState('0');

    const [modalLogin, setModalLogin] = useState({ view: false });
    const [modalService, setModalService] = useState({ view: false });
    const [modalRegister, setModalRegister] = useState({ view: false });

    const onHideLogin = () => {
        setModalLogin({ view: false })
        setModalRegister({ view: true })
    }

    const onHideRegister = () => {
        setModalRegister({ view: false })
        setModalLogin({ view: true })
    }

    return (
        <>
            <div className='box-footer' style={{ background: "#212968" }}>
                <div className="container">
                    <div className='row'>
                        <div className="col-12 col-md-12 col-lg-12 text-start">
                            <div className='pb-1'>
                                <a href="https://seameo-stemed.org" target="_blank" className='btn-link-default'>
                                    <img src={logofoot} className="pe-3" style={{ width: "170px" }} />
                                </a>
                            </div>
                        </div>
                        <div className="col-12 col-md-5 col-lg-5 text-start">
                            <div className='color-white weight-300 font-14'>
                                Southeast Asian Ministers of Education<br /> Organization Regional Centre for STEM Education
                            </div>
                            <div className='color-white weight-300 mt-4 font-14'>
                                <div className='weight-500 mb-2'>Contact Us</div>
                                <div>
                                    11th floor, Natural and Environmental Building,<br />
                                    Science Center for Education, 928 Sukhumvit<br />
                                    Road, Klong Toei, Bangkok 10110, Thailand
                                </div>
                                <div className='mt-3'>
                                    E-mail : steminquiry@seameo-stemed.org
                                </div>
                                <div className='mt-1'>
                                    Website : <a href="https://seameo-stemed.org/" target="_blank" className='color-white'>https://seameo-stemed.org</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-7 col-lg-7 text-start mt-3 mt-md-0">
                            <div className='row'>
                                <div className="col-12 col-md-6 col-lg-6">
                                    <div className='font-14'>
                                        <div>
                                            <span className='click-page'>About</span>
                                        </div>
                                        <div className=''>
                                            <Link to="/programmes/stem_project_competition" transition='default-fade' className='click-page'>
                                                STEM Application Competition
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to="/programmes/capacity_building" transition='default-fade' className='click-page'>
                                                Capacity Building of Educators
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to="/programmes/career_academies" transition='default-fade' className='click-page'>
                                                Career Academies Programme Showcase
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to="/programmes/stem_learning" transition='default-fade' className='click-page'>
                                                STEM Application Resources
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6">
                                    <div className='font-14'>
                                        <div>
                                            <Link onClick={() => setModalService({ view: true })} transition='default-fade' className='click-page'>Calendar
                                            </Link>
                                        </div>
                                        <div className=''>
                                            <Link tp="/partners" transition='default-fade' className='click-page'>
                                                Partners
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to="/announcement" transition='default-fade' className='click-page'>
                                                Announcement
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to="/about/contact" transition='default-fade' className='click-page'>
                                                Contact Us
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to="/about/faq" transition='default-fade' className='click-page'>
                                                FAQ
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='weight-500 color-white ps-0 ps-md-3 mt-3'>
                                Subscribe
                            </div>
                            <div className='row ps-md-3'>
                                <div className="col-12 col-md-6 col-lg-6 ">
                                    <Form.Control
                                        placeholder="E-mail"
                                        aria-label="Recipient's username"
                                        aria-describedby="basic-addon2"
                                        className="text-start input-custom-mail ps-0 py-1 me-3 font-12"
                                    />
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 mt-3 mt-md-0">
                                    <Button onClick={() => setModalService({ view: true })} className="btn btn-default font-14 weight-500" id="button-addon2"
                                        style={{ border: 'unset', borderRadius: "3px", padding: "3px 35px" }}>
                                        Apply
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>                </div>
                <div className="container-fluid mt-4" style={{ borderTop: "1px solid #fff" }}>
                    <div className="container">
                        <div className='row'>
                            <div className="col-12 col-md-6 col-lg-6 text-start pt-3 px-0 px-md-3" >
                                <div className='color-white mt-lg-0 weight-300 font-12'>
                                    Copyright © 2022 SEAMEO STEM-ED | All Rights Reserved.
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 text-start pt-3 px-0 px-md-3" >
                                <div className='color-white mt-lg-0 weight-300 font-12 text-md-end'>
                                    <a className='px-1' href='https://www.facebook.com/SEAMEOSTEMED/' target="_blank">
                                        <img src={fb} style={{ width: "7px" }} />
                                    </a>
                                    {/* <a className='px-1'>
                                        <img src={tw} style={{ width: "12px" }} />
                                    </a> */}
                                    <a className='px-1' href='https://www.youtube.com/channel/UCWvtZhERZQnLbBGGU8Jfu5Q' target="_blank">
                                        <img src={yt} style={{ width: "12px" }} />
                                    </a>
                                    {/* <a className='px-1'>
                                        <img src={ig} style={{ width: "12px" }} />
                                    </a>
                                    <a className='px-1'>
                                        <img src={inn} style={{ width: "12px" }} />
                                    </a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {modalRegister.view ?
                <ModalRegister show={modalRegister.view} onHide={() => setModalRegister({ view: false })} onHideadd={() => onHideRegister()} />
                : null}

            {modalService.view ?
                <ModalService show={modalService.view} onHide={() => setModalService({ view: false })} onClickregister={onHideLogin} />
                : null}

        </>
    );
};

export default Footer;