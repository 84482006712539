import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Modal, Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Link } from "react-tiger-transition";
import Swal from 'sweetalert2'

import banner from '../../assets/img/banner-register.jpg';
import loading from '../../assets/img/loading.gif';
import eye from '../../assets/img/eye.svg';

import AuthService from "../../components/services_member/auth.service";

import ModalLogin from "../../layout/modal/modalLogin";

const ModalRegister = ({ onHideadd, ...datat }) => {

    // localStorage.removeItem("step_1");
    const step2 = JSON.parse(localStorage.getItem("step_2"));

    const formRef = useRef(null);

    const [boxview, setBoxview] = useState(1);
    const [validated, setValidated] = useState(false);
    const [validated2, setValidated2] = useState(false);
    const [validated3, setValidated3] = useState(false);
    const [validated4, setValidated4] = useState(false);

    const [modalLogin, setModalLogin] = useState({ view: false });

    const [loadingView, setLoadingView] = useState(false);

    const handleSubmit = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const data = new FormData(event.target);

        // console.log(data.get('name'));
        // const { email,password,password2 } = event.target.elements;   
        // var errors = [];

        // console.log(errors.push("tel"));

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();

        } else {
            var val = {
                title: data.get('title'),
                name: data.get('name'),
                last_name: data.get('last_name'),
                age: data.get('age'),
                gender: data.get('gender'),
                social: data.get('social'),
            };

            // localStorage.setItem('register', JSON.stringify(val));
            localStorage.setItem("step_1", JSON.stringify(val));
            setBoxview(2)
            formRef.current.reset();
        }
        setValidated(true);
    };

    const handleSubmit2 = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const data = new FormData(event.target);

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();

        } else {
            var val = {
                email: data.get('email'),
                password: data.get('password')
            };
            localStorage.setItem("step_2", JSON.stringify(val));
            setBoxview(3)
            formRef.current.reset();
        }
        setValidated2(true);
    };

    const [errorCheckbox, setErrorCheckbox] = useState(false);

    const handleSubmit3 = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const data = new FormData(event.target);

        // document.querySelectorAll('input[type=checkbox]:checked').length

        // console.log(document.querySelectorAll('input[name="area[]"]:checked').length)



        if (document.querySelectorAll('input[name="area[]"]:checked').length > 0) {
            setErrorCheckbox(false)

            // console.log(data.get('area'))

            if (form.checkValidity() === false) {

                event.preventDefault();
                event.stopPropagation();

            } else {
                var val = {
                    country: data.get('country'),
                    city: data.get('city'),
                    company: data.get('company'),
                    degree: data.get('degree'),
                    occ: data.get('occ'),
                    type: data.get('type'),
                    area: checked,
                    area_type: data.get('area_type'),
                };
                localStorage.setItem("step_3", JSON.stringify(val));
                setBoxview(4)
                formRef.current.reset();
            }
            setValidated3(true);
        } else {
            setErrorCheckbox(true)
        }
    };

    const handleSubmit4 = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const data = new FormData(event.target);

        const step1 = JSON.parse(localStorage.getItem("step_1"));
        const step2 = JSON.parse(localStorage.getItem("step_2"));
        const step3 = JSON.parse(localStorage.getItem("step_3"));

        data.append("title", step1.title);
        data.append("name", step1.name);
        data.append("last_name", step1.last_name);
        data.append("age", step1.age);
        data.append("gender", step1.gender);
        data.append("socialAccount", step1.social);

        data.append("email", step2.email);
        data.append("password", step2.password);

        data.append("country", step3.country);
        data.append("city", step3.city);
        data.append("company", step3.company);
        data.append("degree", step3.degree);
        data.append("occ", step3.occ);
        data.append("type", step3.type);
        data.append("area", step3.area);
        data.append('area_type',step3.area_type);

        // data.append("step1", step1);

        // data.append("type", "add");

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();

        } else {

            // setLoadingView(true);

            AuthService.signup(data).then(
                (res) => {
                    console.log(res.status)
                    if (res.status === 200) {
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: '',
                            text: res.messages,
                            showConfirmButton: false,
                            timer: 1500
                        }).then(function () {
                            // history.push("/table/manage");
                            // window.location.reload()
                            onHideadd();

                            localStorage.removeItem("step_1");
                            localStorage.removeItem("step_2");
                            localStorage.removeItem("step_3");
                        })
                        datat.onHide();
                        setLoadingView(false);
                    } else {

                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: '',
                            text: res.messages,
                            showConfirmButton: false,
                        }).then(function () {
                            // history.push("/table/manage");
                            // window.location.reload()
                            // setBoxview(2)
                            // setErrors({ email: "This email cannot be used.!" })
                        })
                        setLoadingView(false);
                    }
                },
                (error) => {
                    console.log(error);

                }
            );
        }
        setValidated4(true);
    };


    const [form, setForm] = useState({})
    const [errors, setErrors] = useState({})
    // const [errorsemail, setErrorsemail] = useState()

    const findFormErrors = () => {

        const { email, email2, password, password2 } = form

        const newErrors = {};

        // const emailval = errorsemail;

        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[0-9])(?=.{6,})");
        // const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");


        if (!emailRegex.test(email)) {
            newErrors.email = 'This email cannot be used.!'
        }
        if (email2 !== email) {
            newErrors.email2 = 'Confirm email Not Match!'
        }

        // if(emailval === email) {
        //     newErrors.email = 'This email cannot be used.!'
        // }

        if (!strongRegex.test(password)) {
            newErrors.password = 'This Password cannot be used.!'
        }

        if (password2 !== password) {
            newErrors.password2 = 'Confirm Password Not Match!'
        }
        return newErrors
    }

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value
        })
    }

    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordShown2, setPasswordShown2] = useState(false);

    // Password toggle handler
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };
    const togglePassword2 = () => {
        setPasswordShown2(!passwordShown2);
    };

    const autoTab2 = (obj) => {

        var obj_l = obj.target.value;
        const newErrors = findFormErrors()
        // console.log(Object.keys(newErrors).length)
        if (Object.keys(newErrors).length > 0) {
            // We got errors!
            setErrors(newErrors)
        } else {
            setErrors({})
        }
    };

    const [reqSocial, setReqSocial] = useState(false);
    const [reqOther, setOther] = useState(false);
    const [areaother, setAreaother] = useState(false);

    const handleChange = (e) => {

        if (e.target.value === "Social Media") {
            setReqSocial(true)
            setOther(false)
        } else if (e.target.value === "Other") {
            setOther(true)
            setReqSocial(false)
        } else {
            setReqSocial(false)
            setOther(false)
        }
    }

    const [checked, setChecked] = useState([]);

    const handleCheckbox = (e) => {

        var updatedList = [...checked];
        if (e.target.checked) {
            updatedList = [...checked, e.target.value];
        } else {
            updatedList.splice(checked.indexOf(e.target.value), 1);
        }
        setChecked(updatedList);

        // if (document.querySelectorAll('input[name="area[]"]:checked').length > 0) {
        //     setErrorCheckbox(false)

        //     // if (e.target.value === "Other") {
        //     //     setAreaother(true)
        //     // }else{
        //     //     setAreaother(false)
        //     // }

        // } else {
        //     setErrorCheckbox(true)
        //     setAreaother(false)
        // }
    }

    console.log(checked)

    return (
        <>
            <Modal {...datat} className="modal-custom" size="xl" backdrop="static" centered keyboard={false}>
                <Modal.Header closeButton className=''>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-white p-0">
                    <div className="d-block w-100 scroll-custom">
                        <div className='h-100per-modal'>
                            <div className='row'>
                                <div className='col-md-12 col-xl-5'>
                                    <div className='bg-img-center bg-img-left w-100 h-100' style={{backgroundImage:`url(${banner})`}}></div>
                                    {/* <img src={banner} className="w-100" /> */}
                                </div>
                                <div className='col-md-12 col-xl-7 ps-0 position-relative'>
                                    {loadingView ?
                                        <div className='bg-loading'>
                                            <img src={loading} className="icon-loading" />
                                        </div>
                                        : null}

                                    <div className='my-5 px-4  h-100per-modal-b'>

                                        <div className='font-24 weight-700'>Registration Form</div>
                                        <div className='font-14'>Southeast Asian STEM Education Fair & Exposition 2023</div>
                                        <div>
                                            <div className="main-container">
                                                <div className="steps-container">
                                                    <div className={boxview == 1 ? "step in-progress" : "step completed"}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                            <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
                                                        </svg>
                                                        <div className="label completed">
                                                            Step 1
                                                        </div>
                                                        <div className="icon completed">
                                                            <i className="far fa-handshake"></i>
                                                        </div>
                                                    </div>
                                                    <div className={boxview > 1 ? "line next-step-in-progress" : "line prev-step-in-progress"}></div>

                                                    <div className={boxview > 2 ? "step completed" : boxview == 2 ? "step in-progress" : "step"}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                            <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
                                                        </svg>
                                                        <div className={boxview > 1 ? "label completed" : "label loading"}>
                                                            Step 2
                                                        </div>
                                                        <div className="icon">
                                                            <i className="far fa-money-bill-alt"></i>
                                                        </div>
                                                    </div>
                                                    <div className={boxview > 2 ? "line next-step-in-progress" : "line prev-step-in-progress"}></div>

                                                    <div className={boxview > 3 ? "step completed" : boxview == 3 ? "step in-progress" : "step"}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                            <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
                                                        </svg>
                                                        <div className={boxview > 2 ? "label completed" : "label loading"}>
                                                            Step 3
                                                        </div>
                                                        <div className="icon">
                                                            <i className="far fa-newspaper"></i>
                                                        </div>
                                                    </div>
                                                    <div className={boxview > 3 ? "line next-step-in-progress" : "line prev-step-in-progress"}></div>

                                                    <div className={boxview > 4 ? "step completed" : boxview == 4 ? "step in-progress" : "step"}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                            <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
                                                        </svg>
                                                        <div className="label loading">
                                                            Step 4
                                                        </div>
                                                        <div className="icon">
                                                            <i className="far fa-newspaper"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {boxview == 1 ?
                                            <div className='mt-4 '>
                                                <Form noValidate validated={validated} onSubmit={handleSubmit} ref={formRef}>
                                                    <div className='row'>
                                                        <div className='col-xl-3'>
                                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                <Form.Label className='weight-600 font-14'>Title</Form.Label>
                                                                <Form.Select name="title" className='input-custom font-12 ps-2' required>
                                                                    <option value="">Select</option>
                                                                    <option value="Mr.">Mr.</option>
                                                                    <option value="Ms.">Ms.</option>
                                                                    <option value="Dr.">Dr.</option>
                                                                    <option value="Other">Other</option>
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </div>
                                                        <div className='col-xl-4'>
                                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                <Form.Label className='weight-600 font-14'>Name</Form.Label>
                                                                <Form.Control type="text" name='name' placeholder='Name' required className='form-control input-custom ps-0 font-12' />
                                                            </Form.Group>
                                                        </div>
                                                        <div className='col-xl-5'>
                                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                <Form.Label className='weight-600 font-14'>Last Name</Form.Label>
                                                                <Form.Control type="text" name='last_name' placeholder='Last Name' required className='form-control input-custom ps-0 font-12' />
                                                            </Form.Group>

                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-xl-6'>
                                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                <Form.Label className='weight-600 font-14'>Age</Form.Label>
                                                                <Form.Select name="age" required className='input-custom font-12 ps-2'>
                                                                    <option value="">Select</option>
                                                                    <option value="Under 12 years old">Under 12 years old</option>
                                                                    <option value="12-17">12-17</option>
                                                                    <option value="18-24">18-24</option>
                                                                    <option value="25-34">25-34</option>
                                                                    <option value="35-44">35-44</option>
                                                                    <option value="45-54">45-54</option>
                                                                    <option value="55-64">55-64</option>
                                                                    <option value="65 or more">65 or more</option>
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </div>
                                                        <div className='col-xl-6'>
                                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                <Form.Label className='weight-600 font-14'>Gender</Form.Label>
                                                                <Form.Select name="gender" required className='input-custom font-12 ps-2'>
                                                                    <option value="">Select</option>
                                                                    <option value="Male">Male</option>
                                                                    <option value="Female">Female</option>
                                                                    <option value="Prefer not to answer">Prefer not to answer</option>
                                                                </Form.Select>
                                                            </Form.Group>

                                                        </div>
                                                        <div className='col-xl-6'>
                                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                <Form.Label className='weight-600 font-14'>Social Media Account</Form.Label>
                                                                <Form.Control type="text" name='social' placeholder='Type here' className='form-control input-custom ps-0 font-12' />
                                                            </Form.Group>
                                                        </div>
                                                    </div>
                                                    <div className='position-absolute box-absolute-res w-100 px-4' style={{ bottom: "15px", right: "15px" }}>
                                                        <div className='row justify-content-end'>
                                                            <div className='col-4'>
                                                                <span onClick={() => datat.onHide()} className='a-default d-block w-100 py-2 mt-5 cursor-pointer text-end' >
                                                                    Cancel
                                                                </span>
                                                            </div>
                                                            <div className='col-4'>
                                                                <button className='btn-submit d-block w-100 py-2 mt-5 weight-600' type="submit">
                                                                    Next
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Form>
                                            </div>
                                            : boxview == 2 ?
                                                <div className='mt-4 '>
                                                    <Form noValidate validated={validated2} onSubmit={handleSubmit2} ref={formRef}>
                                                        <div className='row'>
                                                            <div className='col-xl-6'>
                                                                <Form.Group className="mb-3 position-relative" controlId="formBasicEmail">
                                                                    <Form.Label className='weight-600 font-14'>Email</Form.Label>
                                                                    <Form.Control type="email" name='email'
                                                                        placeholder='Email' required
                                                                        onKeyUp={autoTab2}
                                                                        onChange={e => setField('email', e.target.value)}
                                                                        isInvalid={!!errors.email}
                                                                        className='form-control input-custom ps-0 font-12' />
                                                                    <Form.Control.Feedback type='invalid' className='position-absolute font-12 text-end'>
                                                                        {errors.email}
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                            </div>
                                                            <div className='col-xl-6'>
                                                                <Form.Group className="mb-3 position-relative" controlId="formBasicEmail">
                                                                    <Form.Label className='weight-600 font-14'>Confirm Email</Form.Label>
                                                                    <Form.Control type="email" name='email2'
                                                                        placeholder='Confirm Email' required
                                                                        onKeyUp={autoTab2}
                                                                        onChange={e => setField('email2', e.target.value)}
                                                                        isInvalid={!!errors.email2}
                                                                        className='form-control input-custom ps-0 font-12' />
                                                                    <Form.Control.Feedback type='invalid' className='position-absolute font-12 text-end'>
                                                                        {errors.email2}
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-xl-6'>
                                                                <Form.Group className="mb-3 position-relative" controlId="formBasicEmail">
                                                                    <Form.Label className='weight-600 font-14'>Password</Form.Label>
                                                                    <Form.Control type={passwordShown ? "text" : "password"}
                                                                        name='password' placeholder='Password' required
                                                                        className='form-control input-custom ps-0 font-12'
                                                                        minLength={6}
                                                                        onKeyUp={autoTab2}
                                                                        onChange={e => setField('password', e.target.value)}
                                                                        isInvalid={!!errors.password}
                                                                    />
                                                                    <span className="sh-pass cursor-pointer" onClick={togglePassword}>
                                                                        <img src={eye} alt="" className="" style={{ width: "20px" }} />
                                                                        {passwordShown ? null : <span className='hide-password'></span>}
                                                                    </span>

                                                                    {/* <Form.Control.Feedback type='invalid' className='position-absolute font-12 text-end'>
                                                                        {errors.password}
                                                                    </Form.Control.Feedback> */}
                                                                </Form.Group>
                                                                <div className='font-11 pt-0'>
                                                                    Your password must be at least 6 characters including upper/lowercase characters and numbers.
                                                                </div>
                                                            </div>
                                                            <div className='col-xl-6'>
                                                                <Form.Group className="mb-3 position-relative" controlId="formBasicEmail">
                                                                    <Form.Label className='weight-600 font-14'>Confirm Password</Form.Label>
                                                                    <Form.Control type={passwordShown2 ? "text" : "password"}
                                                                        placeholder='Confirm Password'
                                                                        name="password2"
                                                                        minLength={6}
                                                                        onKeyUp={autoTab2}
                                                                        onChange={e => setField('password2', e.target.value)}
                                                                        isInvalid={!!errors.password2}
                                                                        required
                                                                        className='form-control input-custom ps-0 font-12' />
                                                                    <span className="sh-pass cursor-pointer" onClick={togglePassword2}>
                                                                        <img src={eye} alt="" className="" style={{ width: "20px" }} />
                                                                        {passwordShown2 ? null : <span className='hide-password'></span>}
                                                                    </span>
                                                                    <Form.Control.Feedback type='invalid' className='position-absolute font-12 text-end'>
                                                                        {errors.password2}
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                            </div>
                                                        </div>
                                                        <div className='position-absolute box-absolute-res w-100 px-4' style={{ bottom: "15px", right: "15px" }}>
                                                            <div className='row justify-content-end'>
                                                                <div className='col-4'>
                                                                    <span onClick={() => setBoxview(1)} className='a-default d-block w-100 py-2 mt-5 cursor-pointer text-end' >
                                                                        Back
                                                                    </span>
                                                                </div>
                                                                <div className='col-4'>
                                                                    <button className='btn-submit d-block w-100 py-2 mt-5 weight-600' type="submit">
                                                                        Next
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Form>
                                                </div>
                                                : boxview == 3 ?
                                                    <div className='mt-4 '>
                                                        <Form noValidate validated={validated3} onSubmit={handleSubmit3} ref={formRef}>
                                                            <div className='row'>
                                                                <div className='col-xl-6'>
                                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                        <Form.Label className='weight-600 font-14'>Country</Form.Label>
                                                                        <Form.Control type="text" name='country' placeholder='Country' required className='form-control input-custom ps-0 font-12' />
                                                                    </Form.Group>
                                                                </div>
                                                                <div className='col-xl-6'>
                                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                        <Form.Label className='weight-600 font-14'>City</Form.Label>
                                                                        <Form.Control type="text" name='city' placeholder='City' required className='form-control input-custom ps-0 font-12' />
                                                                    </Form.Group>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-xl-6'>
                                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                        <Form.Label className='weight-600 font-14'>Institution/Company</Form.Label>
                                                                        <Form.Control type="text" name='company' placeholder='Institution/Company' required className='form-control input-custom ps-0 font-12' />
                                                                    </Form.Group>

                                                                </div>
                                                                <div className='col-xl-6'>
                                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                        <Form.Label className='weight-600 font-14'>Highest Degree</Form.Label>
                                                                        <Form.Select name="degree" required className='input-custom font-12 ps-2'>
                                                                            <option value="">Select</option>
                                                                            <option value="Vocational">Vocational</option>
                                                                            <option value="Bachelor's">Bachelor's</option>
                                                                            <option value="Master's">Master's</option>
                                                                            <option value="PhD">PhD</option>
                                                                            <option value="Other">Other</option>
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                </div>
                                                                <div className='col-xl-6'>
                                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                        <Form.Label className='weight-600 font-14'>Occupation</Form.Label>
                                                                        <Form.Select name="occ" required className='input-custom font-12 ps-2'>
                                                                            <option value="">Select</option>
                                                                            <option value="Student">Student</option>
                                                                            <option value="Teacher">Teacher</option>
                                                                            <option value="University/College Lecturer">University/College Lecturer</option>
                                                                            <option value="School Administrator">School Administrator</option>
                                                                            <option value="Researcher">Researcher</option>
                                                                            <option value="Government Official">Government Official</option>
                                                                            <option value="Other">Other</option>
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                </div>
                                                                <div className='col-xl-6'>
                                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                        <Form.Label className='weight-600 font-14'>Type of Institution/Company</Form.Label>
                                                                        <Form.Select name="type" required className='input-custom font-12 ps-2'>
                                                                            <option value="">Select</option>
                                                                            <option value="School (K-12)">School (K-12)</option>
                                                                            <option value="University/College">University/College</option>
                                                                            <option value="Vocational College">Vocational College</option>
                                                                            <option value="Industry/Business">Industry/Business</option>
                                                                            <option value="Government">Government</option>
                                                                            <option value="Other">Other</option>
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                </div>
                                                                <div className='col-xl-12'>
                                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                        <Form.Label className='weight-600 font-14 mb-0'>
                                                                            Area of Interest <span className='weight-600'> Check all that applied</span>
                                                                        </Form.Label>

                                                                        {errorCheckbox ?
                                                                            <div className='font-12 color-red'>Please select an option</div>
                                                                            : null}
                                                                        <div className='row mt-3'>
                                                                            <div className='col-12 col-md-6'>
                                                                                <div className='position-relative'>
                                                                                    <div className="ex-checkbox">
                                                                                        <input type="checkbox" id="radio_one" onChange={handleCheckbox} name="area[]" value="Research in STEM Education" />
                                                                                        <label className="radio_one mb-2 font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                                            Research in STEM Education
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='position-relative'>
                                                                                    <div className="ex-checkbox">
                                                                                        <input type="checkbox" id="radio_one" onChange={handleCheckbox} name="area[]" value="Development in School" />
                                                                                        <label className="radio_one mb-2 font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                                            Development in School
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='position-relative'>
                                                                                    <div className="ex-checkbox">
                                                                                        <input type="checkbox" id="radio_one" onChange={handleCheckbox} name="area[]" value="Careers/Career Academies" />
                                                                                        <label className="radio_one mb-2 font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                                            Careers/Career Academies
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='position-relative'>
                                                                                    <div className="ex-checkbox">
                                                                                        <input type="checkbox" id="radio_one" onChange={handleCheckbox} name="area[]" value="Policy Development" />
                                                                                        <label className="radio_one mb-2 font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                                            Policy Development
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-12 col-md-6'>
                                                                                <div className='position-relative'>
                                                                                    <div className="ex-checkbox">
                                                                                        <input type="checkbox" id="radio_one" onChange={handleCheckbox} name="area[]" value="Learning Resources" />
                                                                                        <label className="radio_one mb-2 font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                                            Learning Resources
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='position-relative'>
                                                                                    <div className="ex-checkbox">
                                                                                        <input type="checkbox" id="radio_one" onChange={handleCheckbox} name="area[]" value="Teacher Professional Development" />
                                                                                        <label className="radio_one mb-2 font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                                            Teacher Professional Development
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='position-relative'>
                                                                                    <div className="ex-checkbox">
                                                                                        <input type="checkbox" id="radio_one" onChange={() => setAreaother(!areaother)} name="area[]" value="Other" />
                                                                                        <label className="radio_one mb-2 font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                                            Other
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className='ps-4 ms-1 mb-3'>
                                                                                        <Form.Control type="text" name='area_type' placeholder='Type'
                                                                                            className='form-control input-custom ps-0 font-12' required={areaother} />
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                    </Form.Group>
                                                                </div>
                                                            </div>
                                                            <div className='position-absolute box-absolute-res w-100 px-4' style={{ bottom: "15px", right: "15px" }}>
                                                                <div className='row justify-content-end'>
                                                                    <div className='col-4'>
                                                                        <span onClick={() => setBoxview(2)} className='a-default d-block w-100 py-2 mt-5 cursor-pointer text-end' >
                                                                            Back
                                                                        </span>
                                                                    </div>
                                                                    <div className='col-4'>
                                                                        <button className='btn-submit d-block w-100 py-2 mt-5 weight-600' type="submit">
                                                                            Next
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Form>
                                                    </div>
                                                    :
                                                    <div className='mt-4 '>
                                                        <Form noValidate validated={validated4} onSubmit={handleSubmit4} ref={formRef}>
                                                            <div className='row'>
                                                                <div className='col-xl-6'>
                                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                        <Form.Label className='weight-600 font-14'>How did you hear about STEM Expo?</Form.Label>
                                                                        <div>
                                                                            <input type="radio" id="radio_one" name="level" className='position-absolute' value="" style={{ height: 0, opacity: "0" }} required />
                                                                            <div className="invalid-feedback m-0 font-12 mb-3">Please choose a checkbox.</div>
                                                                        </div>
                                                                        <div className='position-relative'>
                                                                            <div className="ex-radio">
                                                                                <input type="radio" id="radio_one" name="level" value="Email" required onChange={handleChange} />
                                                                                <span className="radio_one mb-2 font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                                    Email
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className='position-relative'>
                                                                            <div className="ex-radio">
                                                                                <input type="radio" id="radio_one" onChange={handleChange} name="level" value=" Word of mouth" />
                                                                                <span className="radio_one mb-2 font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                                    Word of mouth
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className='position-relative'>
                                                                            <div className="ex-radio">
                                                                                <input type="radio" id="radio_one" onChange={handleChange} name="level" value="Social Media" />
                                                                                <span className="radio_one mb-2 font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                                    Social Media
                                                                                </span>
                                                                            </div>
                                                                            <div className='ps-4 ms-1 mb-3'>
                                                                                <Form.Control type="text" name='social_type'
                                                                                    placeholder='Type'
                                                                                    className='form-control input-custom ps-0 font-12' required={reqSocial} />
                                                                            </div>
                                                                        </div>
                                                                        <div className='position-relative'>
                                                                            <div className="ex-radio">
                                                                                <input type="radio" id="radio_one" onChange={handleChange} name="level" value="Advertisement" />
                                                                                <span className="radio_one mb-2 font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                                    Advertisement
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className='position-relative'>
                                                                            <div className="ex-radio">
                                                                                <input type="radio" id="radio_one" onChange={handleChange} name="level" value="Other" />
                                                                                <span className="radio_one mb-2 font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                                                    Other
                                                                                </span>
                                                                            </div>
                                                                            <div className='ps-4 ms-1'>
                                                                                <Form.Control type="text" name='other_type'
                                                                                    placeholder='Type'
                                                                                    className='form-control input-custom ps-0 font-12' required={reqOther} />
                                                                            </div>
                                                                        </div>
                                                                    </Form.Group>
                                                                </div>
                                                            </div>

                                                            <div className='position-absolute box-absolute-res w-100 px-4' style={{ bottom: "15px", right: "15px" }}>
                                                                <div className='row justify-content-end'>
                                                                    <div className='col-4'>
                                                                        <span onClick={() => setBoxview(3)} className='a-default d-block w-100 py-2 mt-5 cursor-pointer text-end' >
                                                                            Back
                                                                        </span>
                                                                    </div>
                                                                    <div className='col-4'>
                                                                        <button className='btn-submit d-block w-100 py-2 mt-5 weight-600' type="submit">
                                                                            Next
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Form>
                                                    </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                {/* <Modal.Footer className='bg-white d-block'>
                    <div className='text-start py-2 px-4'>
                        <div className='color-gray'>Don’t have an account?
                            <Link to="/register" transition='default-fade' className="btn-link-default ms-3">Register</Link>
                        </div>
                    </div>
                </Modal.Footer> */}
            </Modal>
        </>
    );
};

export default ModalRegister;