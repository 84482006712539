import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import Header from '../../layout/header';
import Footer from '../../layout/footer';
import Totop from '../../components/toTop';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';


import { Link } from "react-tiger-transition";
import GetService from "../../components/services_member/get.service";

import bg from '../../assets/img/bg-unit.jpg';

const LearningUnit = () => {

    const member = JSON.parse(localStorage.getItem("member_data"));

    // const user = JSON.parse(localStorage.getItem("member"));

    if (!member) {
        window.location.href = "/programmes/stem_learning";
    }

    const { id } = useParams();

    const responsive = {
        0: { items: 1.2 },
        568: { items: 2 },
        1024: { items: 3 },
    };

    const ref = useRef(null);
    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: "smooth" });
    };

    const [datapage, setDatapage] = useState();
    const [datapageOne, setDatapageOne] = useState();
    const [dataconfig, setDataconfig] = useState();
    const [readmore, setReadmore] = useState();

    const fetchData = useCallback(async (id, member_id) => {

        GetService.getUnit(id, member_id).then(
            (response) => {
                if (response.status === 200) {
                    setDatapage(response.data.data)
                    setDatapageOne((response.data.data).filter(valb => valb.id === `${id}`)[0])
                    setDataconfig(response.data.config)
                }
            }, (error) => {
                // window.location.reload();
            }
        )
    });

    useEffect(() => {
        fetchData(id, member ? member.id : 0)
    }, []);

    const handleEp = (idep, link) => {

        // member ? member.id : 0
        // alert(idep)
        const memberid = member ? member.id : 0;

        // alert(memberid)

        GetService.getAddep(idep, memberid).then(
            (response) => {
                if (response.status === 200) {
                    console.log(response.data)
                }

                window.open(link, '_blank');
            }, (error) => {
                // window.location.reload();
            }
        )
    }

    const handleReadmore = (e) => {
        setReadmore(e)
    }



    //   const toggleClass = () => {
    //     setActive(!isActive);
    //   };

    return (
        <>
            <div id="outer-container" ref={ref}>
                <Header page="programmes" />
                <div className="wrapper bg-white box-index">
                    <section className="bg-top-center" id="page-wrap" style={{ backgroundImage: `url(${bg})` }}>
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 position-relative">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12 text-start">
                                                <div className='py-3'>
                                                    <Link to='/' transition='default-fade' className="font-12 a-default">Home </Link>
                                                    <span className='mx-2 font-12'>|</span>
                                                    <Link to='/programmes/stem_learning' transition='default-fade' className="font-12 a-default">STEM Learning Resources</Link>
                                                    <span className='mx-2 font-12'>|</span>
                                                    {datapageOne ?
                                                        <Link to={'/programmes/learning_detail/' + datapageOne.learning_id} transition='default-fade' className="font-12 a-default">
                                                            {datapageOne ? datapageOne.learning_name : null}
                                                        </Link>
                                                        : null}
                                                    <span className='mx-2 font-12'>|</span>
                                                    <span className='font-12'>{datapageOne ? datapageOne.topic : null}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="container mt-5">
                                        <div className="row justify-content-center">
                                            <div className="col-12 col-md-10 col-lg-10 text-center">
                                                <div className='box-unit-ep py-5 px-5'>
                                                    <div className='font-22 weight-600 mb-3'>
                                                        {datapageOne ? datapageOne.topic : null}
                                                    </div>
                                                    <div className='font-14'>
                                                        {datapageOne ? <><div className='text-start' dangerouslySetInnerHTML={{ __html: datapageOne.detail }} /></> : null}
                                                    </div>
                                                    <div className='mt-5'>
                                                        <div className='row'>
                                                            {datapageOne ? (datapageOne.ep).length ? datapageOne.ep.map((val, index) => (
                                                                <div className='col-12 col-md-6 p-1' key={index}>
                                                                    {/* <span target="_blank" className='cursor-pointer box-ep text-start position-relative py-3 font-14'> */}
                                                                    <span className='cursor-pointer box-ep text-start position-relative py-3 font-14'>
                                                                        <div className='position-absolute mx-3 absolute-center weight-500'>{val.topic}</div>
                                                                        <div className='ps-5 px-2'>
                                                                            <div className={readmore === index ? 'h-box active' : 'h-box'} onClick={(e) => handleEp(val.id, val.link)}>
                                                                                <div className='ps-2' style={{ borderLeft: "1px solid " }} dangerouslySetInnerHTML={{ __html: val.detail }} />
                                                                            </div>
                                                                            {readmore !== index ?
                                                                                <div onClick={(e) => handleReadmore(index)} className='ps-2 readmore' style={{ borderLeft: "1px solid " }}>Read more</div>
                                                                                : null}
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                            )) : <div className='p-5'>-- No Data --</div> : <></>}

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="col-12 col-md-10 col-lg-10 text-center">
                                                {datapageOne ?
                                                    <div className='my-5 text-end'>
                                                        <Link to={'/programmes/learning_detail/' + datapageOne.learning_id} className="btn-back px-5 py-1">Back</Link>
                                                    </div>
                                                    : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-center my-5">
                                    <div className="col-12 col-md-10 col-lg-11 position-relative">
                                        <div className='text-start font-14 text-decoration-underline'>
                                            {datapageOne ? datapageOne.learning_title : null}
                                        </div>
                                        <div className='text-start font-22 weight-600 text-decoration-underline'>
                                            {datapageOne ? datapageOne.learning_name : null}
                                        </div>
                                        <div className='my-5'>
                                            <AliceCarousel mouseTracking
                                                touchTracking={false}
                                                infinite
                                                handleDragStart
                                                disableButtonsControls
                                                // disableDotsControls
                                                controlsStrategy="alternate"
                                                responsive={responsive}
                                            >
                                                {datapage ? datapage.map((val, index) => (
                                                    <a href={"/programmes/unit_detail/" + val.id} transition='default-fade' className='d-block px-3 py-3 mb-3 box-unit me-3 text-start' key={index}>
                                                        <div className='weight-700 mb-2'>{val.topic}</div>
                                                        {/* <div className='weight-400 font-14'><div dangerouslySetInnerHTML={{ __html: val.detail }} /></div> */}
                                                        <div className='weight-700 mt-2'>source</div>
                                                        <div className='weight-400 font-14'>{val.source}</div>
                                                    </a>
                                                )) : <div className='col-12 p-5'>-- No Data --</div>}

                                            </AliceCarousel>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Totop page="programmes" handleClick={(e) => handleClick(e)} />
                    <Footer />
                </div>

            </div >

        </>
    );
};

export default LearningUnit;