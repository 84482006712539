import axios from "axios";
// import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const getAllHome = () => {
  return axios.get(API_URL + "/home");
};

const getFaq = () => {
    return axios.get(API_URL + "/faq");
  };

const getCalendar = (startDate) => {
  return axios.get(API_URL + "/calendar?date="+startDate);
};
const getAnnouncement = () => {
  return axios.get(API_URL + "/announcement");
};
const getPagesetting = (id) => {
  return axios.get(API_URL + "/pagessetting/"+id);
};
const getPartners = () => {
  return axios.get(API_URL + "/partners");
};
const getCapacity = () => {
  return axios.get(API_URL + "/capacity");
};
const getCareer = () => {
  return axios.get(API_URL + "/career");
};
const getLearning = () => {
  return axios.get(API_URL + "/learning");
};
const getCompetition = () => {
  return axios.get(API_URL + "/competition");
};

const getUnit = (id,member_id) => {
  return axios.get(API_URL + "/unit/"+id+"?member_id="+member_id);
};

const getAddep = (id,member_id) => {
  return axios.get(API_URL + "/add_ep/"+id+"?member_id="+member_id);
};

const getOtp = (id) => {
  return axios.get(API_URL + "/otp/"+id )
  .then((response) => {
    return response.data;
  });
};

const getService = {
  getAllHome,
  getFaq,
  getCalendar,
  getAnnouncement,
  getPartners,
  getCapacity,
  getCareer,
  getLearning,
  getUnit,
  getCompetition,
  getPagesetting,
  getAddep,
  getOtp
};

export default getService;