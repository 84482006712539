import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Form, Col, Row } from "react-bootstrap";

import Header from '../layout/header';
import Footer from '../layout/footer';
import Totop from '../components/toTop';

// import { Carousel } from 'react-responsive-carousel';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

// import ScrollToTop from "react-scroll-to-top";
import AuthService from "../components/services_member/auth.service";
import GetService from "../components/services_member/get.service";

import { Link } from "react-tiger-transition";
import Swal from 'sweetalert2';

import eye from '../assets/img/eye.svg';


const Newpassword = () => {

    const { id } = useParams();

    let history = useHistory();

    const ref = useRef(null);
    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: "smooth" });
    };

    const [dataconfig, setDataconfig] = useState();
    const [dataotp, setDataotp] = useState(false);

    const fetchData = useCallback(async (id) => {

        GetService.getOtp(id).then(

            (response) => {
                if (response.success === true) {
                    setDataotp(true)
                }
            }, (error) => {
                // window.location.reload();
            }
        )

        GetService.getFaq().then(
            (response) => {
                if (response.status === 200) {
                    setDataconfig(response.data.config)
                }
            }, (error) => {
                // window.location.reload();
            }
        )
    });

    useEffect(() => {
        fetchData(id)
    }, []);

    // console.log(dataconfig)

    const [validated, setValidated] = useState(false);
    const [form, setForm] = useState({})
    const [errors, setErrors] = useState({})

    const handleSubmit = (event) => {

        event.preventDefault();

        const newErrors = findFormErrors()

        const form = event.currentTarget;

        const data = new FormData(event.target);
        
        if (Object.keys(newErrors).length > 0) {

            setErrors(newErrors)
        } else {
            if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
            } else {
                AuthService.resetpass(data).then(
                    (res) => {
                        console.log(res)

                        if (res.status === 200) {

                            Swal.fire({
                                position: 'center',
                                icon: 'success',
                                title: '',
                                text: "The new password has been set successfully",
                                showConfirmButton: false,
                                timer: 1500
                            }).then(function () {
                                history.push("/");
                            })
                        } else {
                            setErrors({ otp: "This OTP cannot be used.!" });
                            Swal.fire({
                                position: 'center',
                                icon: 'success',
                                title: '',
                                text: "Unable to set a new password, please contact the administrator.",
                                showConfirmButton: false,
                                timer: 1500
                            }).then(function () {
                                history.push("/");
                            })
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            }
        }
        setValidated(true);
    };

    const findFormErrors = () => {

        const { password, password2 } = form

        const newErrors = {};

        // const emailval = errorsemail;

        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        // const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[0-9])(?=.{6,})");
        // const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");


        // if (!emailRegex.test(email)) {
        //     newErrors.email = 'This email cannot be used.!'
        // }


        // if(emailval === email) {
        //     newErrors.email = 'This email cannot be used.!'
        // }

        // if (!strongRegex.test(password)) {
        //     newErrors.password = 'This Password cannot be used.!'
        // }
        if (password2 !== password) {
            newErrors.password2 = 'Confirm Password Not Match!'
        }

        return newErrors
    }
    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value
        })
    }

    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordShown2, setPasswordShown2] = useState(false);

    // Password toggle handler
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };
    const togglePassword2 = () => {
        setPasswordShown2(!passwordShown2);
    };

    const autoTab2 = (obj) => {

        var obj_l = obj.target.value;

        const newErrors = findFormErrors()

        if (Object.keys(newErrors).length > 0) {
            // We got errors!
            setErrors(newErrors)
        } else {
            setErrors({})
        }
    };

    console.log(dataotp)

    return (
        <>
            <div id="outer-container" ref={ref}>
                <Header page="programmes" />
                <div className="wrapper bg-white box-index">
                    <section className="" id="page-wrap">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 position-relative">

                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12 text-start">
                                                <div className='py-3'>
                                                    <Link to='/' transition='default-fade' className="font-12 a-default">Home</Link>
                                                    <span className='mx-2 font-12'>|</span>
                                                    <span className='font-12'>New password</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container mt-5">
                                        <div className="row justify-content-center">
                                            <div className="col-12 col-md-6 col-lg-6 text-start pe-md-5">
                                                <div>
                                                    {dataotp ?
                                                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                                            <div className="row mb-5">

                                                                <div className="col-12 col-md-12 col-lg-12">
                                                                    <Form.Group as={Col} md="12" className="position-relative mt-3" controlId="validationCustom01">
                                                                        <Form.Label className='text-gray mb-1 font-14'>New password</Form.Label>
                                                                        <Form.Control
                                                                            required
                                                                            type={passwordShown ? "text" : "password"}
                                                                            placeholder="New password"
                                                                            className=""
                                                                            name="password"
                                                                            minLength={6}
                                                                            onKeyUp={autoTab2}
                                                                            onChange={e => setField('password', e.target.value)}
                                                                            isInvalid={!!errors.password}
                                                                        />
                                                                        <Form.Control.Feedback type='invalid'>
                                                                            {errors.password}
                                                                        </Form.Control.Feedback>
                                                                        <span className="sh-pass cursor-pointer" onClick={togglePassword}>
                                                                            <img src={eye} alt="" className="" />
                                                                            {passwordShown ? <span className='hide-password'></span> : null}
                                                                        </span>
                                                                    </Form.Group>
                                                                    <Form.Group as={Col} md="12" className="position-relative mt-3" controlId="validationCustom01">
                                                                        <Form.Label className='text-gray mb-1 font-14'>Confirm new password</Form.Label>
                                                                        <Form.Control
                                                                            required
                                                                            type={passwordShown2 ? "text" : "password"}
                                                                            placeholder="Confirm new password"
                                                                            className=""
                                                                            name="password2"
                                                                            minLength={6}
                                                                            onKeyUp={autoTab2}
                                                                            onChange={e => setField('password2', e.target.value)}
                                                                            isInvalid={!!errors.password2}
                                                                        />
                                                                        <span className="sh-pass cursor-pointer" onClick={togglePassword2}>
                                                                            <img src={eye} alt="" className="" />
                                                                            {passwordShown2 ? <span className='hide-password'></span> : null}
                                                                        </span>

                                                                        <Form.Control.Feedback type='invalid' className='position-absolute text-end pe-4'>
                                                                            {errors.password2}
                                                                        </Form.Control.Feedback>
                                                                    </Form.Group>
                                                                    <input type="hidden" name="otp" defaultValue={id} />
                                                                </div>
                                                            </div>
                                                            <div className="row my-5">
                                                                <div className="col-12 col-md-12 col-lg-12">
                                                                    <div className='text-end pt-4'>
                                                                        <Link to='/' className="btn-default pe-3">cancel</Link>
                                                                        <button type='submit' className="btn-blue">Update</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Form>
                                                        : <><div className='text-center my-5 py-5'>This link is unavailable or has expired.</div></>}</div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <Totop page="programmes" handleClick={(e) => handleClick(e)} />
                    <Footer />
                </div>

            </div >

        </>
    );
};

export default Newpassword;