import axios from "axios";
import PostService from "./post.service";

const API_URL = process.env.REACT_APP_API_URL;

const signup = (data) => {
  return axios
    .post(API_URL + "/signup", data)
    .then((response) => {
      //   if (response.data.accessToken) {
      //     localStorage.setItem("member", JSON.stringify(response.data));
      //   }
      return response.data;
    });
};

const checkEmail = (data) => {
  return axios
    .post(API_URL + "/checkemail", data)
    .then((response) => {
      //   if (response.data.accessToken) {
      //     localStorage.setItem("member", JSON.stringify(response.data));
      //   }
      return response.data;
    });
};
const resetpass = (data) => {
  return axios
    .post(API_URL + "/resetpass", data)
    .then((response) => {
      //   if (response.data.accessToken) {
      //     localStorage.setItem("member", JSON.stringify(response.data));
      //   }
      return response.data;
    });
};

const login = (data) => {
  return axios
    .post(API_URL + "/login", data)
    .then((response) => {
      if (response.data.status === 200) {
        localStorage.setItem("member", JSON.stringify(response.data.data));        
      }
      return response.data;

    });
};

const logout = () => {
  localStorage.removeItem("member");
  localStorage.removeItem("member_data");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("member"));
};


const authService = {
  signup,
  checkEmail,
  resetpass,
  login,
  logout,
  getCurrentUser,
};

export default authService;