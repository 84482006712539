import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Form, InputGroup, Button } from 'react-bootstrap';
import Moment from 'moment';

import Header from '../layout/header';
import Footer from '../layout/footer';
import Totop from '../components/toTop';

import { Link } from "react-tiger-transition";
import GetService from "../components/services_member/get.service";

import search from '../assets/img/search.svg';
import calendar from '../assets/img/calendar-b.svg';


const Announcement = () => {

    const ref = useRef(null);
    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: "smooth" });
    };

    const [datapage, setDatapage] = useState();
    const [dataconfig, setDataconfig] = useState();

    const fetchData = useCallback(async () => {

        GetService.getAnnouncement().then(
            (response) => {
                if (response.status === 200) {
                    setDatapage(response.data.data)
                    setDataconfig(response.data.config)
                }
            }, (error) => {
                // window.location.reload();
            }
        )
    });

    useEffect(() => {
        fetchData()
    }, []);

    return (
        <>
            <div id="outer-container" ref={ref}>
                <Header page="programmes" />
                <div className="wrapper bg-white box-index">
                    <section className="" id="page-wrap">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 position-relative">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12 text-start">
                                                <div className='py-3'>
                                                    <Link to='/' transition='default-fade' className="font-12 a-default">Home</Link>
                                                    <span className='mx-2 font-12'>|</span>
                                                    <span className='font-12'>Announcement</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container mt-5 mb-5 pb-5">

                                        <div className="row mb-5">
                                            <div className="col-12 col-md-6 col-lg-6 text-start">
                                                <div className='topic-news weight-600 text-start'>
                                                    Announcement
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 text-start text-md-end">
                                                <div className='d-inline-block pt-3'>
                                                    <InputGroup className="mb-3">
                                                        <div className='d-inline me-5'>
                                                            <img src={calendar} style={{ width: "20px" }} />
                                                            <span className='ms-3'>All</span>
                                                        </div>
                                                        <Form.Control
                                                            placeholder="Search"
                                                            aria-label="Recipient's username"
                                                            aria-describedby="basic-addon2"
                                                            className="text-start input-custom ps-0 py-1 me-3 font-12"
                                                        />
                                                        <Button className="btn btn-default" id="button-addon2 p-0 ms-3"
                                                            style={{ border: 'unset', background: "#000", borderRadius: "3px", padding: "0px 5px" }}>
                                                            <img src={search} alt="" className="" style={{ width: "17px" }} />
                                                        </Button>
                                                    </InputGroup>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row py-md-5">
                                            {datapage ? datapage.map((val, index) => (
                                                <div className="col-12 col-md-6 col-lg-6 text-start pb-5" key={index}>
                                                    <div className="row">
                                                        <div className="col-12 col-md-5 col-lg-5 text-start">
                                                            <a href="" className='d-block position-relative box-an'>
                                                                <img src={process.env.REACT_APP_BASE_URL_IMG + '/uploads/announcement/' + val.id + "/" + val.img} className="w-100" />
                                                                <div className='text-over position-absolute'>
                                                                    <div className='btn-center-absolute position-absolute w-100 text-center'>
                                                                        <a href={val.link_to} target="_blank" className="btn btn-default font-14 weight-600" id="button-addon2"
                                                                            style={{ border: 'unset', borderRadius: "3px", padding: "5px 45px" }}>
                                                                            More Detail
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                        <div className="col-12 col-md-7 col-lg-7 text-start">
                                                            <div className='weight-700 font-18 mb-2 mt-3 mt-md-0'>
                                                                {val.topic}
                                                            </div>
                                                            <div className='py-3'>
                                                                <img src={calendar} style={{ width: "20px" }} />
                                                                <span className='ms-3'>{Moment(val.date_active).format('DD/MM/yyyy')} | {Moment(val.date_active).format('HH:mm')}</span>
                                                            </div>
                                                            <div>
                                                                {val.detail}
                                                            </div>
                                                            <div className='mt-4'>
                                                                {val.tag.split(',').map((val, index) => (
                                                                    <span key={index} className='me-2 bg-gray px-4 py-1 font-14'>{val}</span>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )) : <div className='p-5'>-- No Data --</div>}

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <Totop page="programmes" handleClick={(e) => handleClick(e)} />
                    <Footer />
                </div>

            </div >

        </>
    );
};

export default Announcement;