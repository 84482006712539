import React, { useState, useEffect, useRef, useCallback } from 'react';
import Moment from 'moment';

import Header from '../../layout/header';
import Footer from '../../layout/footer';
import Totop from '../../components/toTop';

import { Link } from "react-tiger-transition";
import GetService from "../../components/services_member/get.service";

import ModalLogin from "../../layout/modal/modalLogin";
import ModalRegister from "../../layout/modal/modalRegister";

import topic from '../../assets/img/topic.svg';
import bg from '../../assets/img/bg-capacity.jpg';

const Capacity = () => {

    const user = JSON.parse(localStorage.getItem("member"));

    const ref = useRef(null);
    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: "smooth" });
    };
    // console.log(ref)
    const [datapage, setDatapage] = useState();
    const [dataconfig, setDataconfig] = useState();
    const [datasetting, setDatasetting] = useState();

    const [modalLogin, setModalLogin] = useState({ view: false });
    const [modalRegister, setModalRegister] = useState({ view: false });

    const fetchData = useCallback(async () => {

        GetService.getCapacity().then(
            (response) => {
                if (response.status === 200) {
                    setDatapage(response.data.data)
                    setDataconfig(response.data.config)
                }
            }, (error) => {
                // window.location.reload();
            }
        )

        GetService.getPagesetting(2).then(
            (response) => {
                if (response.status === 200) {
                    setDatasetting(response.data.data)
                }
            }, (error) => {
                // window.location.reload();
            }
        )
    });

    useEffect(() => {
        fetchData()
    }, []);

    // const handleLogin = () => {

    // }

    const onHideLogin = () => {
        setModalLogin({ view: false })
        setModalRegister({ view: true })
    }

    const onHideRegister = () => {
        setModalRegister({ view: false })
        setModalLogin({ view: true })
    }

    // console.log(user)

    return (
        <>
            <div id="outer-container" ref={ref}>
                <Header page="programmes" />
                <div className="wrapper bg-white box-index">
                    <section className="" id="page-wrap">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 position-relative">

                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12 text-start">
                                                <div className='py-3'>
                                                    <Link to='/' transition='default-fade' className="font-12 a-default">Home</Link>
                                                    <span className='mx-2 font-12'>|</span>
                                                    <span className='font-12'>{datasetting ? datasetting.topic : null}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='py-5'>
                                        <div>
                                            <img src={topic} className="me-3 mb-2" style={{ width: "10px", transform: "rotate(90deg)" }} />
                                        </div>
                                        <div className='weight-600 font-22'>
                                            {datasetting ? datasetting.topic : null}
                                        </div>
                                        <div>{datasetting ? datasetting.sub_title : null}</div>
                                    </div>
                                    <div className="w-100 position-relative">
                                        {datasetting ? <>
                                            <img src={process.env.REACT_APP_BASE_URL_IMG + '/uploads/pages/' + datasetting.id + "/" + datasetting.banner} className="w-100 d-none d-md-block" />
                                            <img src={process.env.REACT_APP_BASE_URL_IMG + '/uploads/pages/' + datasetting.id + "/" + datasetting.banner_m} className="w-100 d-block d-md-none" />
                                            </> : null}
                                    </div>

                                    <div className="container">
                                        <div className="row justify-content-center">
                                            <div className="col-12 col-md-12 col-lg-12 text-start">
                                                <div className='py-5 text-center'>
                                                    <div dangerouslySetInnerHTML={{ __html: datasetting ? datasetting.des : null }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="" id="page-wrap">
                        <div className="container">
                            <div className="row mt-4">
                                {datapage ? datapage.map((val, index) => (
                                    <div key={index} className="col-12 col-md-4 col-lg-4 text-start p-3">
                                        <div className='position-relative'>
                                            <img src={process.env.REACT_APP_BASE_URL_IMG + '/uploads/capacity/' + val.id + "/" + val.img} className="w-100" />
                                        </div>
                                        <div className='weight-700 font-22 pt-3'>
                                            {val.topic}
                                        </div>
                                        <div className='pt-2'>
                                            {val.detail}
                                        </div>
                                        <div className="font-14 pt-2 pb-4 color-gray">{Moment(val.date_active).format('DD/MM/yyyy')}</div>
                                        <div className='pb-5 pt-4'>
                                            {user ?
                                                <a href={val.link_zoom} target="_blank" className="btn-apply-border">submission</a>
                                                :
                                                <a onClick={() => setModalLogin({ view: true })} className="btn-apply-border cursor-pointer">Login</a>
                                            }
                                        </div>
                                    </div>
                                )) : <div className='p-5'>-- No Data --</div>}
                            </div>

                        </div>
                    </section>

                    <Totop page="programmes" handleClick={(e) => handleClick(e)} />
                    <Footer />
                </div>

            </div >

            {modalLogin.view ?
                <ModalLogin show={modalLogin.view} onHide={() => setModalLogin({ view: false })} onHideadd={() => onHideLogin()} />
                : null}

            {modalRegister.view ?
                <ModalRegister show={modalRegister.view} onHide={() => setModalRegister({ view: false })} onHideadd={() => onHideRegister()} />
                : null}

        </>
    );
};

export default Capacity;