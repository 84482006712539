import React, { useState, useEffect, useRef } from 'react';

import p1 from '../assets/img/to-top.svg';
import enjoy from '../assets/img/enjoy.svg';

const Totop = (prop) => {

    // const ref = useRef(null);
    const [enjoyview, setEnjoyview] = useState();

    const handleHide = () => {
        setEnjoyview(false)
        //     ref.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        prop.page === 'home' ?
        setEnjoyview(true)
        : setEnjoyview(false)
    }, []);

    return (
        <>
            {enjoyview ?
                <div className='position-fixed' style={{ top: "50%", left: "15px", background: "unset", border: "unset" }}>
                    <div className='position-relative'>
                        <img src={enjoy} style={{ width: "160px" }} />
                        <span onClick={(e) => handleHide(e)} className='position-absolute cursor-pointer text-white' style={{ top: "-10px", right: "15px" }}>X</span>
                    </div>
                </div>
                : null}
            <button className='position-fixed' style={{ bottom: "15px", right: "15px", background: "unset", border: "unset" }}>
                <span onClick={() => prop.handleClick()}>
                    <img src={p1} style={{ width: "45px" }} />
                </span>
            </button>

        </>
    );
};

export default Totop;