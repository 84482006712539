import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Link } from "react-tiger-transition";
import Swal from 'sweetalert2'

import folder from '../../assets/img/folder.svg';
import loading from '../../assets/img/loading.gif';
import eye from '../../assets/img/eye.svg';

import AuthService from "../../components/services_member/auth.service";

const ModalLogin = ({ onHideadd, refreshData, ...datat }) => {

    let history = useHistory();

    const [validated, setValidated] = useState(false);
    const [validatedreset, setValidatedreset] = useState(false);
    const [clssLoading, setClssLoading] = useState(false);
    const [errors, setErrors] = useState(false)
    const [errorsreset, setErrorsreset] = useState(false)
    const [boxRegister, setBoxregister] = useState({ id: 1 });

    // localStorage.setItem("member_data", JSON.stringify(response.member));

    const handleSubmit = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const data = new FormData(event.target);

        // console.log(data.get('name'));
        // const { email,password,password2 } = event.target.elements;   
        var errors = [];

        // console.log(errors.push("tel"));

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();

        } else {

            setClssLoading(true);

            try {
                AuthService.login(data).then(
                    (res) => {
                        if (res.status === 200) {
                            // history.push("/business");                              
                            // setClssLoading(false);
                            // history.push("/branch");
                            window.location.reload();
                            // datat.onHide();
                            // refreshData();
                            // setdatau(res.status);
                            // setClssLoading(false);
                            // console.log(res)
                        } else {
                            setErrors(true)
                            setClssLoading(false);
                        }
                    },
                    (error) => {
                        console.log(error);
                        setClssLoading(false);
                        // setErrors(true)
                    }
                );
            } catch (err) {
                console.log(err);
                // setErrors(true)
            }
        }

        setValidated(true);
    };


    const handleSubmitreset = (event) => {

        event.preventDefault();
        const form = event.currentTarget;
        const data = new FormData(event.target);

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();

        } else {

            setClssLoading(true);

            AuthService.checkEmail(data).then(
                (res) => {
                    // console.log(res)
                    if (res.status === 200) {

                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: '',
                            text: 'Please check your email.',
                            showConfirmButton: false,
                            timer: 1500
                        }).then(function () {
                            // handleModalhide();
                            window.location.reload();
                        })

                    } else {
                        setErrorsreset(true)
                        setClssLoading(false);
                    }
                },
                (error) => {
                    setClssLoading(false);
                    setErrorsreset(true)
                }
            );
        }

        setValidatedreset(true);
    };

    const [passwordShown, setPasswordShown] = useState(false);
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    return (
        <>
            <Modal {...datat} className="modal-custom" size="md" backdrop="static" centered keyboard={false}>
                <Modal.Header closeButton className=''>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-white">
                    <div className="d-block w-100 scroll-custom">
                        <div className='h-100per-modal py-3 px-4'>

                            {boxRegister.id === 1 ? <>
                                <div className=''>
                                    <div className='topic-news weight-700'>Log in</div>
                                </div>
                                <div className='mt-4'>
                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            {/* <Form.Label>Email address</Form.Label> */}
                                            <Form.Control type="email" name='email' className='form-control input-custom ps-0 font-14' placeholder='Email' required />
                                            <Form.Text className="text-end d-block color-white font-12 weight-300">
                                                {/* <em>* อีเมลที่ลงทะเบียน</em> */}
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group className="mb-0 position-relative" controlId="formBasicPassword">
                                            {/* <Form.Label>Password</Form.Label> */}
                                            <Form.Control
                                                type={passwordShown ? "text" : "password"}
                                                name='password'
                                                className='form-control input-custom ps-0 font-14' placeholder='Password' required />
                                            <Form.Text className="text-end d-block color-white font-12 weight-300">
                                                {/* <em>* หมายเลขโทรศัพท์ที่ลงทะเบียน</em> */}
                                            </Form.Text>
                                            <span className="sh-pass cursor-pointer" onClick={togglePassword}>
                                                <img src={eye} alt="" className="" style={{ width: "20px" }} />
                                                {passwordShown ? null : <span className='hide-password'></span>}
                                            </span>
                                        </Form.Group>
                                        {errors ? (
                                            <div className='font-12 mb-0' style={{ color: "#dc3545" }}>Invalid email or password !</div>
                                        ) : ""}
                                        <div className='position-relative mt-3'>
                                            <div className="ex-checkbox4 d-inline-block">
                                                <input type="checkbox" id="radio_one" name="remember" value="1" />
                                                <label className="radio_one mb-2 font-14" style={{ width: "100%", paddingLeft: "30px" }} >
                                                    Remember me
                                                </label>
                                            </div>
                                            <div className='position-absolute' style={{ right: "0", top: "0" }}>
                                                <span onClick={() => setBoxregister({ id: "2" })} className="a-default font-14 cursor-pointer" style={{ textDecoration: "underline" }}>
                                                    Forgot Password?
                                                </span>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-7'>
                                                <button className='btn-submit d-block w-100 py-2 mt-5 weight-600' type="submit">
                                                    {clssLoading ? <span><i className="fa fa-spinner fa-spin" /></span> : <span>Log in</span>}
                                                </button>
                                            </div>
                                            <div className='col-5'>
                                                <span onClick={() => datat.onHide()} className='a-default d-block w-100 py-2 mt-5 cursor-pointer' >
                                                    Cancel
                                                </span>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </> : <>
                                <div className='pb-5'>
                                    <div className='topic-news weight-700'>Reset password</div>
                                </div>
                                <div>
                                    <Form noValidate validated={validatedreset} onSubmit={handleSubmitreset}>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            {/* <Form.Label>Email address</Form.Label> */}
                                            <Form.Control type="email" name='email' className='form-control input-custom ps-0 font-14' placeholder='Email' required />
                                            <Form.Text className="text-end d-block font-12 weight-300">
                                                <em>* registered email</em>
                                            </Form.Text>
                                        </Form.Group>
                                        {errorsreset ? (
                                            <div className='font-12 mb-0 text-center' style={{ color: "#dc3545" }}>This email address does not exist in the database. !</div>
                                        ) : ""}

                                        <button className='btn-submit d-block w-100 py-2 mt-5 weight-600' type="submit">
                                            {clssLoading ? <span><i className="fa fa-spinner fa-spin" /></span> : <span>Confirm</span>}
                                        </button>

                                    </Form>
                                </div>
                            </>}

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='bg-white d-block'>
                    <div className='text-start py-2 px-4'>
                        <div className='color-gray'>Don’t have an account?
                            <span onClick={() => onHideadd()} className="btn-link-default ms-3 cursor-pointer">Register</span>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalLogin;