import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Modal, Button, Form } from "react-bootstrap";

import Header from '../../layout/header';
import Footer from '../../layout/footer';
import Totop from '../../components/toTop';

// import { Carousel } from 'react-responsive-carousel';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

// import ScrollToTop from "react-scroll-to-top";

import GetService from "../../components/services_member/get.service";

import { Link } from "react-tiger-transition";

import fb from '../../assets/img/Facebook.svg';
import tw from '../../assets/img/Twitter.svg';
import yt from '../../assets/img/Youtube.svg';
import ig from '../../assets/img/Instagram.svg';
import inn from '../../assets/img/In.svg';


const Stemexpo2023 = () => {

    const ref = useRef(null);
    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: "smooth" });
    };

    const [dataconfig, setDataconfig] = useState();

    const fetchData = useCallback(async () => {

        GetService.getFaq().then(
            (response) => {
                if (response.status === 200) {
                    setDataconfig(response.data.config)
                }
            }, (error) => {
                // window.location.reload();
            }
        )
    });

    useEffect(() => {
        fetchData()
    }, []);

    // console.log(dataconfig)

    const [validated, setValidated] = useState(false);
    const handleSubmit = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const data = new FormData(event.target);

        // console.log(data.get('name'));
        // const { email,password,password2 } = event.target.elements;   
        // var errors = [];

        // console.log(errors.push("tel"));

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();

        } else {
            // var val = {
            //     title: data.get('title'),
            //     name: data.get('name'),
            //     last_name: data.get('last_name'),
            //     age: data.get('age'),
            //     gender: data.get('gender'),
            //     social: data.get('social'),
            // };

            // localStorage.setItem('register', JSON.stringify(val));
            // localStorage.setItem("step_1", JSON.stringify(val));
            // setBoxview(2)
            // formRef.current.reset();
        }
        setValidated(true);
    };

    return (
        <>
            <div id="outer-container" ref={ref}>
                <Header page="programmes" />
                <div className="wrapper bg-white box-index">
                    <section className="" id="page-wrap">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 position-relative">

                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12 text-start">
                                                <div className='py-3'>
                                                    <Link to='/' transition='default-fade' className="font-12 a-default">Home</Link>
                                                    <span className='mx-2 font-12'>|</span>
                                                    <span className='font-12'>STEM Expo 2023</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        <div className="container mt-5">
                                            <div className="row justify-content-center">
                                                <div className="col-12 col-md-6 col-lg-6 text-start pe-md-5">
                                                    <div className='topic-news weight-600'>
                                                        STEM Expo 2023
                                                    </div>
                                                    <div className='mt-4'>
                                                        <div className='weight-700'>
                                                            PULLMAN BANGKOK KING POWER
                                                        </div>
                                                        <div className='pt-2 mb-3'>
                                                            8/2 Rangnam Road, Thanon-Phayathai, Ratchathewi, 10400Bangkok, Thailand                                                            
                                                        </div>
                                                        <div>Phone: +66 (0)2 680 9999</div>
                                                        <div>Fax: +66 (0)2 680 9998</div>
                                                        <div className='pt-3 mb-5 pb-5'>
                                                            <a href="https://www.pullmanbangkokkingpower.com" target="_blank">https://www.pullmanbangkokkingpower.com</a>
                                                        </div>
                                                    </div>                                                   
                                                </div>
                                              
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <Totop page="programmes" handleClick={(e) => handleClick(e)} />
                    <Footer />
                </div>

            </div >

        </>
    );
};

export default Stemexpo2023;