import React from 'react';

import { BrowserRouter as Router, Switch } from 'react-router-dom';
// import logo from './logo.svg';

import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';

import './assets/css/style.css';
import './assets/css/menu.css';
import './assets/css/respornsive.css';
import './assets/css/radioandcheckbox.css';

import Home from './pages/home';
import Stemproject from './pages/programmes/stem_project';
import Capacity from './pages/programmes/capacity';
import Career from './pages/programmes/career_academies';
import Stemlearning from './pages/programmes/stem_learning';
import Stemlearningdetail from './pages/programmes/learning_detail';
import Stemunitdetail from './pages/programmes/learning_unit';

import Stemexpo2023 from './pages/about/stemexpo_2023';
import Organizing from './pages/about/organizing';
import Contact from './pages/about/contact';
import Faq from './pages/about/faq';

import Calendar from './pages/calendar';
import Announcement from './pages/announcement';
import Partners from './pages/partners';

import Newpassword from './pages/newpassword';

import { Navigation, Route, Screen, glide, fade } from "react-tiger-transition";

glide({
  name: 'glide-left'
});
glide({
  name: 'glide-right',
  direction: 'right'
});
fade({
  name: "default-fade"
});

function App() {

  return (

    <div className="App">
      <Router basename="">
        <Switch>
          <Navigation>
            <Route exact path="/"><Screen><Home></Home></Screen></Route>
            <Route path="/about/stemexpo_2023"><Screen><Stemexpo2023></Stemexpo2023></Screen></Route>
            <Route path="/about/organizing"><Screen><Organizing></Organizing></Screen></Route>
            <Route path="/about/contact"><Screen><Contact></Contact></Screen></Route>
            <Route path="/about/faq"><Screen><Faq></Faq></Screen></Route>

            <Route path="/programmes/stem_project_competition"><Screen><Stemproject></Stemproject></Screen></Route>
            <Route path="/programmes/capacity_building"><Screen><Capacity></Capacity></Screen></Route>            
            <Route path="/programmes/stem_learning"><Screen><Stemlearning></Stemlearning></Screen></Route>            
            <Route path="/programmes/career_academies"><Screen><Career></Career></Screen></Route>  
            <Route path="/programmes/learning_detail/:id"><Screen><Stemlearningdetail></Stemlearningdetail></Screen></Route>           
            <Route path="/programmes/unit_detail/:id"><Screen><Stemunitdetail></Stemunitdetail></Screen></Route>           

            <Route path="/calendar"><Screen><Calendar></Calendar></Screen></Route>
            <Route path="/announcement"><Screen><Announcement></Announcement></Screen></Route>
            <Route path="/partners"><Screen><Partners></Partners></Screen></Route>

            <Route path="/newpassword/:id"><Screen><Newpassword></Newpassword></Screen></Route>   
          </Navigation>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
