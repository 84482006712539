import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import AuthService from "../../components/services_member/auth.service";

const ModaService = ({ onClickregister, ...datat }) => {

    const onClickHide = () => {
        onClickregister();
        datat.onHide();
    }
    return (
        <>
            <Modal {...datat} className="modal-custom" size="md" backdrop="static" centered keyboard={false}>
                <Modal.Header closeButton className=''>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-white">
                    <div className="d-block w-100 scroll-custom">
                        <div className='h-100per-modal py-5 px-4'>                            
                            <div className='mt-4'>
                                This page is under development, we are currently working on this page. In the meantime, please be a member to keep up with the latest 
                                <span className='cursor-pointer a-here' onClick={() => onClickHide()}> here</span>
                            </div>

                        </div>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    );
};

export default ModaService;