import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Accordion } from 'react-bootstrap';

import Header from '../layout/header';
import Footer from '../layout/footer';
import Totop from '../components/toTop';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
// import 'bootstrap-daterangepicker/daterangepicker.css';
import Calendarview from 'react-calendar';
import 'react-calendar/dist/Calendar.css';


import { Link } from "react-tiger-transition";
import GetService from "../components/services_member/get.service";
import moment from 'moment';
import bg from '../assets/img/bg-calendar.jpg';
import calendar from '../assets/img/calendar.svg';
import event from '../assets/img/event.svg';

const Calendar = () => {

    const ref = useRef(null);
    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: "smooth" });
    };

    const [datapage, setDatapage] = useState([]);
    const [dataconfig, setDataconfig] = useState();
    const [dataPt, setDataPt] = useState([]);

    const [startDate, setStartDate] = useState(new Date());
    const [date, setDate] = useState(startDate);

    const dateoption = {
        locale: {
            format: 'MM/YYYY'
        },
        // minDate: todayDate,
        // startDate: data.dateday,
        // endDate: startDate,
        showDropdowns: true,
        singleDatePicker: true,
        autoApply: true,
        timePicker24Hour: true
    };

    const fetchData = useCallback(async (dateMonth) => {

        GetService.getCalendar(moment(dateMonth).format('YYYY-MM-DD')).then(
            (response) => {
                if (response.status === 200) {
                    setDatapage(response.data.data)
                    setDataconfig(response.data.config)
                    setDataPt(response.data.comming)
                }
            }, (error) => {

            }
        )
    });

    useEffect(() => {
        fetchData(startDate)
    }, []);

    const handleMonth = (date) => {
        setStartDate(date)
        setDate((date))
        fetchData(date)
    }

    // console.log(datapage)

    return (
        <>
            <div id="outer-container" ref={ref}>
                <Header page="programmes" />
                <div className="wrapper bg-white box-index">
                    <section className="bg-top-center" id="page-wrap" style={{ backgroundImage: `url(${bg})` }}>
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 position-relative">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12 text-start">
                                                <div className='py-3'>
                                                    <Link to='/' transition='default-fade' className="font-12 a-default">Home</Link>
                                                    <span className='mx-2 font-12'>|</span>
                                                    <span className='font-12'>Calendar</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="container mt-md-5 mb-5 pb-5">
                                        <div className="row border-bottom pb-5">
                                            <div className="col-12 col-md-4 col-lg-4 text-start">
                                                <div className='topic-news weight-600 text-start mt-5'>
                                                    Calendar
                                                </div>
                                                <div className='font-20 weight-600'>Upcoming event</div>
                                                <div className='mt-3'>
                                                    {dataPt ? dataPt.map((val, index) => (
                                                        <div className='mb-3 box-calendar py-3' key={index}>
                                                            <div>
                                                                <div className='weight-700' style={{ fontSize: '50px', lineHeight: '1' }}>
                                                                    {moment(val.date_event).format('DD')}
                                                                </div>
                                                                <div className='weight-700 font-18' style={{ textTransform: 'uppercase' }}>
                                                                    {moment(val.date_event).format('MMM YYYY')}
                                                                </div>
                                                            </div>
                                                            <div className='weight-600 mt-2'>Event/Activity</div>
                                                            <div className='font-14'>
                                                                <div dangerouslySetInnerHTML={{ __html: val.detail }} />
                                                            </div>
                                                            <div className='weight-600 mt-3'>Mode/Location</div>
                                                            <div className='font-14'>{val.mode}</div>
                                                            <div className='font-14'>{val.location}</div>
                                                        </div>
                                                    )) : '-- No Event --'}
                                                </div>

                                            </div>
                                            <div className="col-12 col-md-8 col-lg-8 text-start">
                                                <div className='mt-5'>
                                                    <div className='mb-4'>
                                                        <div className='d-inline-block'>
                                                            <div className='weight-700 font-20 mb-2 pt-3'>Select :</div>
                                                        </div>
                                                        <div className='d-inline-block'>
                                                            <div className='position-relative mb-2 ps-3'>
                                                                <DatePicker className='form-control' dateFormat="MM/yyyy" showMonthYearPicker selected={startDate} onChange={(date) => handleMonth(date)} />
                                                                <div className="position-absolute" style={{ right: "10px", bottom: "7px" }}>
                                                                    <img src={calendar} alt="" style={{ height: "18px" }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='pt-5 p-md-5 bg-white shadow-sm'>
                                                        <div className='mb-5'>
                                                            <div className='row'>
                                                                <div className="col-12 col-md-3 col-lg-3 text-start">
                                                                    <div className='weight-700' style={{ fontSize: '42px', lineHeight: '1', textTransform: 'uppercase' }}>
                                                                        {moment(startDate).format('MMM')}
                                                                    </div>
                                                                    <div className='weight-700 font-20'>
                                                                        {moment(startDate).format('YYYY')}
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-9 col-lg-9 text-start">
                                                                    <div>
                                                                        <Calendarview activeStartDate={date} value={date} locale="en"
                                                                            tileClassName={({ date, view }) => {
                                                                                if (datapage.find(x => x.date_event === moment(date).format("YYYY-MM-DD"))) {
                                                                                    return 'highlight'
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    {/* <p className='text-center'>
                                                                        <span className='bold'>Selected Date:</span>{' '}
                                                                        {date.toDateString()}
                                                                    </p> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='weight-700 mb-4'><img src={event} alt="" style={{ height: "10px" }} className="me-3" /> Event</div>
                                                        <div className=''>
                                                            {datapage.length ? datapage.map((val, index) => (
                                                                <div className='mb-3 box-calendar py-3' key={index}>
                                                                    <div className='border-left ps-3'>
                                                                        <div>
                                                                            <div className='weight-700 font-18' style={{ textTransform: 'uppercase' }}>
                                                                                {moment(val.date_event).format('DD MMM YYYY')}
                                                                            </div>
                                                                        </div>
                                                                        <div className='weight-600 mt-2'>Event/Activity</div>
                                                                        <div className='font-14'>
                                                                            <div dangerouslySetInnerHTML={{ __html: val.detail }} />
                                                                        </div>
                                                                        <div className='weight-600 mt-3'>Mode/Location</div>
                                                                        <div className='font-14'>{val.mode}</div>
                                                                        <div className='font-14'>{val.location}</div>
                                                                    </div>
                                                                </div>
                                                            )) : <div className="text-center py-5">-- No Event --</div>}
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className='mt-4'>

                                                </div>
                                            </div>
                                        </div>
                                        {/* {datapage.map((val, index) => (
                                                <div className="row align-items-center border-bottom py-5" key={index}>
                                                    <div className="col-12 col-md-3 col-lg-3 text-start">
                                                        <img src={process.env.REACT_APP_BASE_URL_IMG + '/uploads/partners/' + val.id + "/" + val.img} className="w-100 p-4" />
                                                    </div>
                                                    <div className="col-12 col-md-9 col-lg-9 text-start">
                                                        <div className='weight-700 font-18 mb-2'>{val.topic}</div>
                                                        <div>
                                                            {val.detail}
                                                        </div>
                                                        <div className='mt-4'>
                                                            <a href={val.link_web} className='btn-border-blue' target="_blank">Go to Website</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))} */}
                                    </div>

                                    {/* <div className="container mt-5 mb-5 pb-5">
                                        <div className='topic-news weight-600 text-start '>
                                            Implementing Partners
                                        </div>
                                        {dataPt ?
                                            <div className="row align-items-center border-bottom py-5" >
                                                <div className="col-12 col-md-12 col-lg-12 text-start">
                                                    <div dangerouslySetInnerHTML={{ __html: dataPt.detail }} />
                                                </div>

                                            </div>
                                            : <div className='p-5'>-- No Data --</div>}
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </section>
                    <Totop page="programmes" handleClick={(e) => handleClick(e)} />
                    <Footer />
                </div>

            </div >

        </>
    );
};

export default Calendar;