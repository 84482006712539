import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const getAllProvince = () => {
  return axios.post(API_URL + "/province/province_all");
};

// const getAllBranchPosts = (id) => {
//   return axios.post(API_URL + "/branch/branch_all", {id});
// };

const getAllPrivatePosts = () => {
  // return axios.get(API_URL + "/data_private", { headers: authHeader.authHeaderMember() });
  return axios.get("https://www.stemexpo.seameo-stemed.org/seameo-expo-service/admin/member_private", { headers: authHeader.authHeaderMember() })
  // return axios.get(API_URL + "/member_private",{ headers: authHeader.authHeaderMember() })
  .then((response) => {
    return response.data;
  });
};


const postService = {
  getAllProvince,
  getAllPrivatePosts,
};

export default postService;