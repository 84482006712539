import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import Header from '../../layout/header';
import Footer from '../../layout/footer';
import Totop from '../../components/toTop';

// import { Carousel } from 'react-responsive-carousel';

// import ScrollToTop from "react-scroll-to-top";


import ModalLogin from "../../layout/modal/modalLogin";
import ModalRegister from "../../layout/modal/modalRegister";

import { Link } from "react-tiger-transition";
import GetService from "../../components/services_member/get.service";

import topic from '../../assets/img/topic.svg';
import calendar from '../../assets/img/calendar.svg';
import bg from '../../assets/img/banner-pro-1.jpg';
import fteacher from '../../assets/img/for-teacher.svg';
import fstudent from '../../assets/img/for-student.svg';
import cat1 from '../../assets/img/cat-1.jpg';
import cat2 from '../../assets/img/cat-2.jpg';
import cat3 from '../../assets/img/cat-3.jpg';


const Stemproject = () => {

    const user = JSON.parse(localStorage.getItem("member"));

    const ref = useRef(null);
    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: "smooth" });
    };

    const [modalLogin, setModalLogin] = useState({ view: false });
    const [modalRegister, setModalRegister] = useState({ view: false });

    const onHideLogin = () => {
        setModalLogin({ view: false })
        setModalRegister({ view: true })
    }

    const onHideRegister = () => {
        setModalRegister({ view: false })
        setModalLogin({ view: true })
    }

    const [datapage, setDatapage] = useState();
    const [dataconfig, setDataconfig] = useState();
    const [datacat, setDatacat] = useState([]);
    const [datasetting, setDatasetting] = useState();

    const fetchData = useCallback(async () => {

        GetService.getCompetition().then(
            (response) => {
                if (response.status === 200) {
                    setDatapage(response.data.data)
                    setDataconfig(response.data.config)
                    setDatacat(response.data.cat)
                }
            }, (error) => {
                // window.location.reload();
            }
        )
        GetService.getPagesetting(1).then(
            (response) => {
                if (response.status === 200) {
                    setDatasetting(response.data.data)
                }
            }, (error) => {
                // window.location.reload();
            }
        )
    });

    useEffect(() => {
        fetchData()
    }, []);

    return (
        <>
            <div id="outer-container" ref={ref}>
                <Header page="programmes" />
                <div className="wrapper bg-white box-index">
                    <section className="" id="page-wrap">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 position-relative">

                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12 text-start">
                                                <div className='py-3'>
                                                    <Link to='/' transition='default-fade' className="font-12 a-default">Home</Link>
                                                    <span className='mx-2 font-12'>|</span>
                                                    <span className='font-12'>{datasetting ? datasetting.topic : null}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='py-5'>
                                        <div>
                                            <img src={topic} className="me-3 mb-2" style={{ width: "10px", transform: "rotate(90deg)" }} />
                                        </div>
                                        <div className='weight-600 font-22'>
                                            {datasetting ? datasetting.topic : null}
                                        </div>
                                        <div>{datasetting ? datasetting.sub_title : null}</div>
                                    </div>
                                    <div className="w-100 position-relative">
                                        {datasetting ? <>
                                            <img src={process.env.REACT_APP_BASE_URL_IMG + '/uploads/pages/' + datasetting.id + "/" + datasetting.banner} className="w-100 d-none d-md-block" />
                                            <img src={process.env.REACT_APP_BASE_URL_IMG + '/uploads/pages/' + datasetting.id + "/" + datasetting.banner_m} className="w-100 d-block d-md-none" />
                                        </> : null}
                                    </div>

                                    <div className="container">
                                        <div className="row justify-content-center">
                                            <div className="col-12 col-md-12 col-lg-10 text-start">
                                                <div className='py-5 text-center'>
                                                    <div dangerouslySetInnerHTML={{ __html: datasetting ? datasetting.des : null }} />
                                                </div>
                                                <div className='px-md-3 mx-md-3 px-lg-5 mx-lg-5'>
                                                    <div className='px-3 px-md-5 py-4 border-all'>
                                                        <div><img src={calendar} className="me-3 mb-2" style={{ width: "25px" }} /><b>Submission Period</b></div>
                                                        <div className='pt-2'>
                                                            <span className='weight-600'>Competition Open :</span> {datapage ? datapage.open : null}<br />
                                                            <span className='weight-600'>Review of Entries :</span> {datapage ? datapage.review : null}<br />
                                                            <span className='weight-600'>Notification of Entry Status/Finalists :</span> {datapage ? datapage.noti : null}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div dangerouslySetInnerHTML={{ __html: datapage ? datapage.detail : null }} />
                                                    </div>

                                                    {/* <div className='font-18 mb-3 mt-5'><b>Target</b></div>
                                                    <div>
                                                        This STEM Education competition targeted three groups of students from the SEAMEO member countries ─ which are secondary school students (i.e., grades 6/7-12, including non-formal education students); vocational and technical education students; and university students (undergraduate level).
                                                    </div>
                                                    <div className='mt-4'>
                                                        <b>*     Finalists will present their projects at the expo</b><br /><br />
                                                        <span className='weight-600'>Date :</span> Friday, 10 March 2023	<br />
                                                        <span className='weight-600'>Time :</span> To be announced<br />
                                                        <span className='weight-600'>Location :</span> Bangkok, Thailand (Pullman Bangkok King Power Hotel)
                                                    </div>

                                                    <div className='font-18 mb-3 mt-5'><b>Guidelines</b></div>
                                                    <div>
                                                        After identifying one STEM-related problem or challenge (that can be solved with a budget of $300 USD or less), students will propose, build, develop and test a solution to their problems.
                                                    </div>
                                                    <div className='mt-4'>
                                                        <b>For the problem or challenge identified students must:</b><br />
                                                        <div className='pt-2'>
                                                            <ul className='ps-3'>
                                                                <li>Apply scientific investigation</li>
                                                                <li>Integrate an engineering design practice with a supporting test</li>
                                                                <li>Demonstrate the use of research and/or data collection and analysis to find out more&nbsp; &nbsp;about the problem</li>
                                                                <li>Demonstrate how STEM is used or related to solving the problem</li>
                                                                <li>Provide a prototype or model (a prototype is a “full-size” working representation of the solution to the problem or challenge.&nbsp; A model is often a “smaller” detailed non-working representation of the solution to the problem or challenge and is used to&nbsp; &nbsp; illustrate how the problem or solution could be solved)</li>
                                                                <li>Prepare a short presentation about the project solution (photographs and/or videos&nbsp; are encouraged)&nbsp;</li>
                                                            </ul>
                                                        </div>
                                                    </div>

                                                    <div className='font-18 mb-3 mt-5'><b>Benefits</b></div>
                                                    <div className='mt-4 mb-5'>
                                                        <div className='pt-2'>
                                                            <ul className='ps-3'>
                                                                <li>Engage and connect with professionals, community, and industry experts</li>
                                                                <li>Exposed to entrepreneurship</li>
                                                                <li>Enhance communication, problem-solving, critical thinking, and creative thinking skills</li>
                                                                <li>Enrich STEM knowledge and education</li>
                                                                <li>Showcase and recognition of your work</li>
                                                                <li>Exposed to civic and entrepreneurship mindsets</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='border-top py-3 mt-3'>
                                                        * All entries will be assessed by a group of STEM experts; each category will receive 10 awards
                                                        for each student group.
                                                    </div> */}

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="" id="page-wrap">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 position-relative" style={{ background: "#DBDFFD" }}>
                                    <div className="container">
                                        <div className="row justify-content-center">
                                            <div className="col-12 col-md-10 col-lg-10 text-start">
                                                <div className='px-md-3 px-lg-5'>
                                                    <div className='px-md-0 py-5 py-md-5'>
                                                        <div className='text-center'>
                                                            <div className='weight-600 font-22'>
                                                                Complete Guidelines
                                                            </div>
                                                            <div className='pt-4 mt-2 mb-3 row'>
                                                                <div className='col-12 col-md-4 px-3 mb-3'>
                                                                    {user ?
                                                                        datapage ?
                                                                            <a href={process.env.REACT_APP_BASE_URL_IMG + "/uploads/competition/1/" + datapage.for_t} download target="_blank" className='box-for p-3 d-block'>
                                                                                <div className='text-end font-12'>Download PDF &gt;</div>
                                                                                <div className='pt-2'>
                                                                                    <img src={fteacher} className="me-3 mb-2" style={{ width: "35px" }} />
                                                                                    <span className='font-20 weight-700'>For Teacher</span>
                                                                                </div>
                                                                            </a>
                                                                            : null
                                                                        :
                                                                        <a onClick={() => setModalLogin({ view: true })} className='box-for p-3 d-block cursor-pointer'>
                                                                            <div className='text-end font-12'>Download PDF &gt;</div>
                                                                            <div className='pt-2'>
                                                                                <img src={fteacher} className="me-3 mb-2" style={{ width: "35px" }} />
                                                                                <span className='font-20 weight-700'>For Teacher</span>
                                                                            </div>
                                                                        </a>
                                                                    }
                                                                </div>
                                                                <div className='col-12 col-md-4 px-3 mb-3'>
                                                                    {user ? datapage ?
                                                                        <a href={process.env.REACT_APP_BASE_URL_IMG + "/uploads/competition/1/" + datapage.for_s} download target="_blank" className='box-for p-3 d-block'>
                                                                            <div className='text-end font-12'>Download PDF &gt;</div>
                                                                            <div className='pt-2'>
                                                                                <img src={fstudent} className="me-3 mb-2" style={{ width: "35px" }} />
                                                                                <span className='font-20 weight-700'>For Student</span>
                                                                            </div>
                                                                        </a>
                                                                        : null :
                                                                        <a onClick={() => setModalLogin({ view: true })} className='box-for p-3 d-block cursor-pointer'>
                                                                            <div className='text-end font-12'>Download PDF &gt;</div>
                                                                            <div className='pt-2'>
                                                                                <img src={fstudent} className="me-3 mb-2" style={{ width: "35px" }} />
                                                                                <span className='font-20 weight-700'>For Student</span>
                                                                            </div>
                                                                        </a>
                                                                    }
                                                                </div>
                                                                <div className='col-12 col-md-4 px-3 mb-3'>
                                                                    {user ? datapage ?
                                                                        <a href={process.env.REACT_APP_BASE_URL_IMG + "/uploads/competition/1/" + datapage.for_j} download target="_blank" className='box-for p-3 d-block'>
                                                                            <div className='text-end font-12'>Download PDF &gt;</div>
                                                                            <div className='pt-2'>
                                                                                <img src={fstudent} className="me-3 mb-2" style={{ width: "35px" }} />
                                                                                <span className='font-20 weight-700'>Judging Guidelines</span>
                                                                            </div>
                                                                        </a>
                                                                        : null :
                                                                        <a onClick={() => setModalLogin({ view: true })} className='box-for p-3 d-block cursor-pointer'>
                                                                            <div className='text-end font-12'>Download PDF &gt;</div>
                                                                            <div className='pt-2'>
                                                                                <img src={fstudent} className="me-3 mb-2" style={{ width: "35px" }} />
                                                                                <span className='font-20 weight-700'>Judging Guidelines</span>
                                                                            </div>
                                                                        </a>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="" id="page-wrap" style={{ backgroundColor: "#F0F0F0" }}>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-12 col-lg-12 text-start">
                                    <div className='weight-600 font-22 text-center pt-5'>
                                        Categories of Challenge
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                {datacat.length ? datacat.map((val,index) => (
                                    <div className="col-12 col-md-4 col-lg-4 text-start p-3" key={index}>
                                        <div className='position-relative'>
                                            <img src={process.env.REACT_APP_BASE_URL_IMG + "/uploads/categories/"+val.id+"/" + val.img} className="w-100" />
                                            <div className='position-absolute absolute-bottom px-3 pb-2 weight-700 font-22'>
                                                {val.topic}
                                            </div>
                                        </div>
                                        <div className='pt-3 pb-4'>
                                            {val.detail}
                                        </div>
                                    </div>
                                   )) : null}

                                {/* <div className="col-12 col-md-4 col-lg-4 text-start p-3">
                                    <div className='position-relative'>
                                        <img src={cat2} className="w-100" />
                                        <div className='position-absolute absolute-bottom px-3 pb-2 weight-700 font-22'>
                                            Industry Problem/<br />Challenge
                                        </div>
                                    </div>
                                    <div className='pt-3 pb-4'>
                                        Problems encountered in health and wellness, hospitality and culinary arts, information technology, smart agriculture, and renewable energy industries.
                                    </div>
                                </div>

                                <div className="col-12 col-md-4 col-lg-4 text-start p-3">
                                    <div className='position-relative'>
                                        <img src={cat3} className="w-100" />
                                        <div className='position-absolute absolute-bottom px-3 pb-2 weight-700 font-22'>
                                            Community <br />& School
                                        </div>
                                    </div>
                                    <div className='pt-3 pb-4'>
                                        Problems considered by social, economic, or environmental impacts.
                                    </div>
                                </div> */}
                            </div>
                            <div className="row mt-5 pb-5">
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className='text-center'>
                                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSenQr9zhU-kggaLMT6nPrBJKgb2MYwGgES9b39HLk_bvhonhg/viewform?vc=0&c=0&w=1&flr=0" target="_blank" className="btn-apply">submission</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Totop page="programmes" handleClick={(e) => handleClick(e)} />
                    <Footer />
                </div>

            </div >

            {modalLogin.view ?
                <ModalLogin show={modalLogin.view} onHide={() => setModalLogin({ view: false })} onHideadd={() => onHideLogin()} />
                : null}

            {modalRegister.view ?
                <ModalRegister show={modalRegister.view} onHide={() => setModalRegister({ view: false })} onHideadd={() => onHideRegister()} />
                : null}

        </>
    );
};

export default Stemproject;