import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Accordion } from 'react-bootstrap';

import Header from '../../layout/header';
import Footer from '../../layout/footer';
import Totop from '../../components/toTop';

import { Link } from "react-tiger-transition";
import GetService from "../../components/services_member/get.service";

import bg from '../../assets/img/bg-faq.jpg';
import imenu from '../../assets/img/topic.svg';

const Faq = () => {

    const ref = useRef(null);
    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: "smooth" });
    };

    const [datapage, setDatapage] = useState();
    const [dataconfig, setDataconfig] = useState();

    const fetchData = useCallback(async () => {

        GetService.getFaq().then(
            (response) => {
                if (response.status === 200) {
                    setDatapage(response.data.data)
                    setDataconfig(response.data.config)
                }
            }, (error) => {
                // window.location.reload();
            }
        )
    });

    useEffect(() => {
        fetchData()
    }, []);

    return (
        <>
            <div id="outer-container" ref={ref}>
                <Header page="programmes" />
                <div className="wrapper bg-white box-index">
                    <section className="" id="page-wrap">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 position-relative">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12 text-start">
                                                <div className='py-3'>
                                                    <Link to='/' transition='default-fade' className="font-12 a-default">Home</Link>
                                                    <span className='mx-2 font-12'>|</span>
                                                    <span className='font-12'>FAQ</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container mt-5 mb-5 pb-5">
                                        <div className="row justify-content-center">
                                            <div className="col-12 col-md-4 col-lg-4 text-start">
                                                <div className='py-3 pe-md-5'>
                                                    <img src={bg} className="w-100" />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-8 col-lg-8 text-start">
                                                <div className='topic-news weight-600'>
                                                    FAQ
                                                </div>
                                                <div className='acc-custom'>
                                                    <Accordion defaultActiveKey="">
                                                        {datapage ? datapage.map((val, index) => (
                                                            <Accordion.Item eventKey={index}>
                                                                <Accordion.Header>
                                                                    {val.question}
                                                                    <div className='position-absolute' style={{ right: "0" }}>
                                                                        <img src={imenu} style={{ width: "7px" }} />
                                                                    </div>
                                                                </Accordion.Header>
                                                                <Accordion.Body className='color-gray'>
                                                                    {val.answer}
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        )) : <div className='p-5'>-- No Data --</div>}
                                                    </Accordion>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <Totop page="programmes" handleClick={(e) => handleClick(e)} />
                    <Footer />
                </div>

            </div >

        </>
    );
};

export default Faq;