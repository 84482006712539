import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import { slide as Menu } from 'react-burger-menu';
import { Form, InputGroup, Button, Dropdown, DropdownButton } from 'react-bootstrap';

import ModalLogin from "../layout/modal/modalLogin";
import ModalRegister from "../layout/modal/modalRegister";
import ModalService from "../layout/modal/modalService";

import search from '../assets/img/search.svg';
import searchM from '../assets/img/searchM.svg';
import sorn from '../assets/img/sorn.svg';
import logo from '../assets/img/logo.svg';
import logo2 from '../assets/img/logoStemFair2023.png';
import login from '../assets/img/login.svg';
import close from '../assets/img/close-menu.svg';
import mail from '../assets/img/Letter.svg';
import fb from '../assets/img/Facebook.svg';
import tw from '../assets/img/Twitter.svg';
import yt from '../assets/img/Youtube.svg';
import ig from '../assets/img/Instagram.svg';
import inn from '../assets/img/In.svg';

import { Link } from "react-tiger-transition";

import AuthService from "../components/services_member/auth.service";
import PostService from "../components/services_member/post.service";

// import Menu from '../components/menu';

const Header = (data) => {
    let history = useHistory();
    // const { t } = useTranslation();

    const user = JSON.parse(localStorage.getItem("member"));
    const memberdata = localStorage.getItem("member_data");

    // const memberdata = JSON.parse(localStorage.getItem("member_data"));

    // if (!user) {
    //     // window.location.href = "/";
    //     console.log('no login')
    // } else {
    //     console.log('login')
    // }

    // console.log(memberdata)

    const [datamember, setDatamember] = useState();


    const fetchData = useCallback(async () => {
        // console.log(user)
        if (!memberdata) {

            PostService.getAllPrivatePosts().then(

                (response) => {
                    if (response.status === 200) {
                        localStorage.setItem("member_data", JSON.stringify(response.data_member));
                        setDatamember(response.data_member);
                    }
                }, (error) => {
                    // window.location.reload();
                }
            )
        } else {
            setDatamember(JSON.parse(memberdata));
        }
    });

    useEffect(() => {
        let timer1 = setTimeout(() => fetchData(), 500);
        return () => {
            clearTimeout(timer1);
        };
    }, []);

    const [menuOpen, setMenuOpen] = useState(false);
    const [menusubOpen, setMenusubOpen] = useState({ view: false, id: 0 });
    const [modalLogin, setModalLogin] = useState({ view: false });
    const [modalRegister, setModalRegister] = useState({ view: false });
    const [modalService, setModalService] = useState({ view: false });

    const handleMenuChange = (state) => {
        setMenuOpen(state.isOpen)
    }

    const toggleMenu = () => {
        // setMenuOpen(true); 
        setMenuOpen(false)
    }

    const handleMenusubChange = (state, id) => {
        if (menusubOpen.id === id) {
            setMenusubOpen({ view: !menusubOpen.view, id: id })
        } else {
            setMenusubOpen({ view: true, id: id })
        }

    }
    const Split = (string, digi) => {
        if (string) {
            const char = string.split(',');
            return char[digi];
        }
    }
    const onHideLogin = () => {
        setModalLogin({ view: false })
        setModalRegister({ view: true })
    }

    const onHideRegister = () => {
        setModalRegister({ view: false })
        setModalLogin({ view: true })
    }

    const Logout = () => {
        localStorage.removeItem("member");
        localStorage.removeItem("member_data");
        localStorage.removeItem("step_1");
        localStorage.removeItem("step_2");
        localStorage.removeItem("step_3");

        window.location.reload();
    }

    // console.log(datamember)

    return (
        <>
            <div className="d-block d-md-none menu-res">
                <div className="line-gra position-absolute"></div>
                <div className='logo-home me-5 text-start'>
                    <a href="https://seameo-stemed.org" target="_blank" className='btn-link-default border-0 px-0 '>
                        <img src={logo} alt="" className="" />
                    </a>
                    <a href="/" className='btn-link-default border-0 px-0 '>
                        <img src={logo2} alt="" className="pb-3" style={{ marginLeft: "-10px" }} />
                    </a>
                </div>
                <div className='right' >
                    <Menu className='bg-menu-m box-bg-img-cover' right pageWrapId={"page-wrap"} uterContainerId={"outer-container"} isOpen={menuOpen} onStateChange={(state) => handleMenuChange(state)}>
                        <div onClick={() => toggleMenu()} className='text-end px-2 pt-3 font-20'>
                            <img src={close} className="" style={{ width: "45px" }} />
                        </div>
                        <div className='mt-3 px-3 ps-4'>
                            <div className='d-block'>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        placeholder="Search"
                                        aria-label="Recipient's username"
                                        aria-describedby="basic-addon2"
                                        className="text-start input-custom ps-0 py-1 me-3 font-12"
                                    />
                                    <Button className="btn btn-default" id="button-addon2 p-0 ms-3"
                                        style={{ border: 'unset', background: "#000", borderRadius: "3px", padding: "0px 5px" }}>
                                        <img src={search} alt="" className="" style={{ width: "17px" }} />
                                    </Button>
                                </InputGroup>
                            </div>
                            <div className='d-block text-end'>
                                {datamember ? <>
                                    <div className='d-block'>
                                        <img src={login} alt="" className="me-3 d-inline-block mb-2" style={{ width: "25px" }} />
                                        <DropdownButton id="dropdown-basic-button" title={datamember ? datamember.name : null} className="d-inline-block dp-menu btn-login font-14 ps-0 pe-3 dp-name">
                                            <Dropdown.Item onClick={() => Logout()}>Log out</Dropdown.Item>
                                        </DropdownButton>
                                    </div>
                                    {/* <div className='d-block'>
                                        <button className="btn-signup font-14" disabled>Register</button>
                                    </div> */}
                                    </>
                                    : <>
                                        <div className='d-block'>
                                            <button className="btn-login font-14 ps-0 pe-0 mb-2" onClick={() => setModalLogin({ view: true })}>
                                                <img src={login} alt="" className="me-3" style={{ width: "25px" }} /> Log in
                                            </button>
                                        </div>
                                        <div className='d-block'>
                                            <button className="btn-signup font-14" onClick={() => setModalRegister({ view: true })}>Register</button>
                                        </div>
                                    </>
                                }
                            </div>
                            <div className='d-block text-end mt-3'>
                                <a className='px-2' href='https://www.facebook.com/SEAMEOSTEMED/' target="_blank">
                                    <img src={fb} style={{ width: "10px" }} />
                                </a>
                                {/* <a className='px-2'>
                                    <img src={tw} style={{ width: "15px" }} />
                                </a> */}
                                <a className='px-2' href='https://www.youtube.com/channel/UCWvtZhERZQnLbBGGU8Jfu5Q'>
                                    <img src={yt} style={{ width: "15px" }} />
                                </a>
                                {/* <a className='px-2'>
                                    <img src={ig} style={{ width: "15px" }} />
                                </a>
                                <a className='px-2'>
                                    <img src={inn} style={{ width: "15px" }} />
                                </a> */}
                            </div>
                        </div>
                        <ul className='menu-m'>
                            <li className={menusubOpen.id === 1 ? menusubOpen.view ? "nav-item has-submenu menu-open" : "nav-item has-submenu" : "nav-item has-submenu"}>
                                <a className="nav-link" href="#" onClick={(state) => handleMenusubChange(state, 1)}>
                                    About <img src={sorn} className="arrow90 ms-2" style={{ width: "7px" }} />
                                </a>
                                <ul className="submenu collapse">
                                    <li>
                                        <Link to="/about/stemexpo_2023" transition='default-fade' className="btn-link-default">
                                            STEM Expo 2023
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/about/organizing" transition='default-fade' className="btn-link-default">
                                            Organizing Committee 2023”
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/about/contact" transition='default-fade' className="btn-link-default">
                                            Contact Us
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/about/faq" transition='default-fade' className="btn-link-default">
                                            FAQ
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className={menusubOpen.id === 2 ? menusubOpen.view ? "nav-item has-submenu menu-open" : "nav-item has-submenu" : "nav-item has-submenu"}>
                                <a className="nav-link" href="#" onClick={(state) => handleMenusubChange(state, 2)}>
                                    Programmes <img src={sorn} className="arrow90 ms-2" style={{ width: "7px" }} />
                                </a>
                                <ul className="submenu collapse">
                                    <li>
                                        <Link to="/programmes/stem_project_competition" transition='default-fade' className="btn-link-default">
                                            STEM Project Competition
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/programmes/capacity_building" transition='default-fade' className="btn-link-default">
                                            Capacity Building of Educators
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/programmes/career_academies" transition='default-fade' className="btn-link-default">
                                            Career Academies Programme Showcase
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/programmes/stem_learning" transition='default-fade' className="btn-link-default">
                                            STEM Learning Resources
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <Link to="/calendar" transition='default-fade' className={data.page === "calendar" ? "btn-link-default un active" : "btn-link-default un"}>
                                    Calendar
                                </Link>
                            </li>
                            <li>
                                <Link to="/announcement" transition='default-fade' className={data.page === "thaiDress" ? "btn-link-default un active" : "btn-link-default un"}>
                                    Announcement
                                </Link>
                            </li>
                            <li>
                                <Link to="/partners" transition='default-fade' className={data.page === "engagementDress" ? "btn-link-default un active" : "btn-link-default un"}>
                                    Partners
                                </Link>
                            </li>
                        </ul>
                    </Menu>
                </div>
            </div>

            <header className='position-sticky top-0 d-none d-md-block bg-menu-top'>
                <div className='line-gra position-absolute'></div>
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-12 col-md-12 col-lg-12 position-relative text-end">
                            <div className='d-inline-block pt-3'>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        placeholder="Search"
                                        aria-label="Recipient's username"
                                        aria-describedby="basic-addon2"
                                        className="text-start input-custom ps-0 py-1 me-3 font-12"
                                    />
                                    <Button className="btn btn-default" id="button-addon2 p-0 ms-3"
                                        style={{ border: 'unset', background: "#000", borderRadius: "3px", padding: "0px 5px" }}>
                                        <img src={search} alt="" className="" style={{ width: "17px" }} />
                                    </Button>
                                </InputGroup>
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-4 position-relative">
                            <div className='logo-home'>
                                <a href="https://seameo-stemed.org" target="_blank" className='btn-link-default border-0 px-0 '>
                                    <img src={logo} alt="" className="" />
                                </a>
                                <a href="/" className='btn-link-default border-0 px-0 '>
                                    <img src={logo2} alt="" className="pb-3" />
                                </a>
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-8 position-relative">
                            <div className='menu-top-right text-end'>
                                <div className=''>

                                    {datamember ? <>
                                        <div className='d-inline-block'>
                                            <img src={login} alt="" className="me-3 d-inline-block" style={{ width: "25px" }} />
                                            <DropdownButton id="dropdown-basic-button" title={datamember ? datamember.name : null} className="d-inline-block dp-menu btn-login font-14 ps-0 pe-3 dp-name">
                                                <Dropdown.Item onClick={() => Logout()}>Log out</Dropdown.Item>
                                            </DropdownButton>
                                        </div>
                                        <div className='d-inline-block'>
                                            <button className="btn-signup font-14" disabled>Register</button>
                                        </div></>
                                        : <>
                                            <div className='d-inline-block'>
                                                <button className="btn-login font-14 ps-0 pe-4" onClick={() => setModalLogin({ view: true })}>
                                                    <img src={login} alt="" className="me-3" style={{ width: "25px" }} /> Log in
                                                </button>
                                            </div>
                                            <div className='d-inline-block'>
                                                <button className="btn-signup font-14" onClick={() => setModalRegister({ view: true })}>Register</button>
                                            </div>
                                        </>
                                    }


                                    <div className='d-inline-block ps-3'>
                                        <a className='px-1' href='https://www.facebook.com/SEAMEOSTEMED/' target="_blank">
                                            <img src={fb} style={{ width: "7px" }} />
                                        </a>
                                        {/* <a className='px-1'>
                                            <img src={tw} style={{ width: "12px" }} />
                                        </a> */}
                                        <a className='px-1' href='https://www.youtube.com/channel/UCWvtZhERZQnLbBGGU8Jfu5Q'>
                                            <img src={yt} style={{ width: "12px" }} />
                                        </a>
                                        {/* <a className='px-1'>
                                            <img src={ig} style={{ width: "12px" }} />
                                        </a>
                                        <a className='px-1'>
                                            <img src={inn} style={{ width: "12px" }} />
                                        </a> */}
                                    </div>
                                </div>
                            </div>
                            <ul className='menu-top p-0' style={{ margin: "0 0 0 auto" }}>

                                <li>
                                    <DropdownButton id="dropdown-basic-button" title="About" className="dp-menu">
                                        <Dropdown.Item href="/about/stemexpo_2023">STEM Expo 2023</Dropdown.Item>
                                        <Dropdown.Item href="/about/organizing">Organizing Committee 2023</Dropdown.Item>
                                        <Dropdown.Item href="/about/contact">Contact Us</Dropdown.Item>
                                        <Dropdown.Item href="/about/faq">FAQ</Dropdown.Item>
                                    </DropdownButton>
                                </li>
                                <li>
                                    <DropdownButton id="dropdown-basic-button" title="Programmes" className="dp-menu">
                                        <Dropdown.Item href="/programmes/stem_project_competition">STEM Project Competition</Dropdown.Item>
                                        <Dropdown.Item href="/programmes/capacity_building">Capacity Building of Educators</Dropdown.Item>
                                        <Dropdown.Item href="/programmes/career_academies">Career Academies Programme Showcase</Dropdown.Item>
                                        <Dropdown.Item href="/programmes/stem_learning">STEM Learning Resources</Dropdown.Item>
                                    </DropdownButton>
                                </li>
                                <li>
                                    <Link to="/calendar" transition='default-fade' className={data.page === "thaiDress" ? "btn-link-default un active" : "btn-link-default un"}>
                                        Calendar
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/announcement" transition='default-fade' className={data.page === "thaiDress" ? "btn-link-default un active" : "btn-link-default un"}>
                                        Announcement
                                    </Link>
                                </li>
                                <li className='pe-0'>
                                    <Link to="/partners" transition='default-fade' className={data.page === "engagementDress" ? "btn-link-default pe-0 un active" : "btn-link-default pe-0 un"}>
                                        Partners
                                    </Link>
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>
            </header>
            {modalLogin.view ?
                <ModalLogin show={modalLogin.view} onHide={() => setModalLogin({ view: false })} onHideadd={() => onHideLogin()} />
                : null}

            {modalRegister.view ?
                <ModalRegister show={modalRegister.view} onHide={() => setModalRegister({ view: false })} onHideadd={() => onHideRegister()} />
                : null}
            {modalService.view ?
                <ModalService show={modalService.view} onHide={() => setModalService({ view: false })} onClickregister={onHideLogin} />
                : null}
        </>
    );
};

export default Header;