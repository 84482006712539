import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import Header from '../layout/header';
import Footer from '../layout/footer';
import Totop from '../components/toTop';

// import { Carousel } from 'react-responsive-carousel';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

// import ScrollToTop from "react-scroll-to-top";

import GetService from "../components/services_member/get.service";

import { Link } from "react-tiger-transition";

import sorn from '../assets/img/sorn.svg';
import topic from '../assets/img/topic.svg';
import banner from '../assets/img/banner.jpg';
import bannerM from '../assets/img/bannerM.jpg';
import banner2 from '../assets/img/banner-2.png';
import banner2M from '../assets/img/banner-2-M.png';

import p1 from '../assets/img/p-1.svg';
import p2 from '../assets/img/p-2.svg';
import p3 from '../assets/img/p-3.svg';
import p4 from '../assets/img/p-4.svg';

import img1 from '../assets/img/img-1.png';
import obj1 from '../assets/img/obj-1.png';
import obj2 from '../assets/img/obj-2.png';
import obj3 from '../assets/img/obj-3.png';

import bg from '../assets/img/bg-1.jpg';
import bgM from '../assets/img/bg-1M.jpg';
import bg2 from '../assets/img/bg-2.jpg';
import bg2M from '../assets/img/bg-2M.jpg';
import bg3 from '../assets/img/bg-3.jpg';
import bg3M from '../assets/img/bg-3M.jpg';
import bg4 from '../assets/img/bg-4.jpg';
import bg4M from '../assets/img/bg-4M.jpg';

const Home = () => {

    const handleDragStart = (e) => e.preventDefault();

    const responsive = {
        0: { items: 1.2 },
        568: { items: 2 },
        1024: { items: 5 },
    };

    // useEffect(() => {
    //     window.scrollTo({ top: 50, left: 0 })
    // }, [])

    const ref = useRef(null);
    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: "smooth" });
    };

    const [datapage, setDatapage] = useState();
    const [dataconfig, setDataconfig] = useState();
    const [dataabout, setDataabout] = useState();
    const [datajoin, setDatajoin] = useState();
    const [dataob, setDataob] = useState([]);

    const fetchData = useCallback(async () => {

        GetService.getAllHome().then(
            (response) => {
                if (response.status === 200) {
                    setDatapage(response.data.data)
                    setDataconfig(response.data.config)
                    setDataabout(response.data.about[0])
                    setDatajoin(response.data.about[1])
                    setDataob(response.data.ob)
                }
            }, (error) => {
                // window.location.reload();
            }
        )
    });

    useEffect(() => {
        fetchData()
    }, []);

    // console.log(datapage)


    return (
        <>
            <div id="outer-container" ref={ref}>
                <Header page="home" />
                <div className="wrapper bg-white box-index">
                    <section className="pb-md-5 pb-xl-0" id="page-wrap">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 position-relative">
                                    <div className="w-100 position-relative" style={{backgroundImage: `url(${bg})`,backgroundRepeat: 'no-repeat',backgroundSize: "cover", backgroundPosition: "top"}}>
                                        {/* <img src={bg} className="w-100 d-none d-md-block" /> */}
                                        <div className='w-100'>
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-12 col-md-12 col-lg-12 position-relative p-0">
                                                        <div className='banner-custom d-none d-md-block'>
                                                            <AliceCarousel mouseTracking
                                                                touchTracking={false}
                                                                infinite
                                                                handleDragStart
                                                                controlsStrategy="alternate"
                                                                renderPrevButton={() => {
                                                                    return <p className="position-absolute box-absolute-center">
                                                                        <img src={sorn} className="sorn-left" />
                                                                    </p>
                                                                }}
                                                                renderNextButton={() => {
                                                                    return <p className="position-absolute box-absolute-center">
                                                                        <img src={sorn} className="sorn-right" />
                                                                    </p>
                                                                }} >
                                                                {datapage ? datapage.map((val, index) => (
                                                                    <div className='position-relative gar-black' key={index}>
                                                                        {val.button_link ?
                                                                            <a href={val.button_link} target="_blank">
                                                                                <img src={process.env.REACT_APP_BASE_URL_IMG + '/uploads/banner/' + val.id + "/" + val.img} className="w-100" />
                                                                            </a>
                                                                            : <img src={process.env.REACT_APP_BASE_URL_IMG + '/uploads/banner/' + val.id + "/" + val.img} className="w-100" />}
                                                                    </div>
                                                                )) : null}

                                                            </AliceCarousel>
                                                        </div>
                                                        <div className='banner-custom d-block d-md-none'>
                                                            <AliceCarousel mouseTracking
                                                                touchTracking={true}
                                                                infinite
                                                                handleDragStart
                                                                controlsStrategy="alternate"
                                                                disableButtonsControls
                                                                disableDotsControls
                                                            >
                                                                {datapage ? datapage.map((val, index) => (
                                                                    <div className='position-relative gar-black' key={index}>
                                                                        {val.button_link ?
                                                                            <a href={val.button_link} target="_blank">
                                                                                <img src={process.env.REACT_APP_BASE_URL_IMG + '/uploads/banner/' + val.id + "/" + val.img_m} className="w-100" />
                                                                            </a>
                                                                            : <img src={process.env.REACT_APP_BASE_URL_IMG + '/uploads/banner/' + val.id + "/" + val.img_m} className="w-100" />}
                                                                    </div>
                                                                )) : null}
                                                            </AliceCarousel>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row mt-xl-5 pt-md-3 pt-xl-5'>
                                                    <div className="col-12 col-md-12 col-lg-6 position-relative p-0">
                                                        <img src={bgM} className="w-100 d-block d-md-none" />
                                                    </div>
                                                    <div className="col-12 col-md-12 col-lg-6 position-relative text-start pe-md-0 pe-xl-5">
                                                        <div className='topic-news weight-600'>
                                                            <img src={topic} className="me-3 mb-2" style={{ width: "10px" }} /> {dataabout ? dataabout.topic : null}
                                                        </div>
                                                        <div className='font-14 weight-500 pe-xl-5'>
                                                            <div dangerouslySetInnerHTML={{ __html: dataabout ? dataabout.detail : null }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className='position-relative mt-md-5 pt-md-5 mt-xl-0 pt-xl-0'>
                        <img src={bg2} className="w-100 d-none d-md-block" />
                        <img src={bg2M} className="w-100 d-block d-md-none" />
                        <div className='position-absolute absolute-center w-100'>
                            <div className="container">
                                <div className='topic-news weight-600 mb-md-1 mb-xl-5'>
                                    Objectives
                                </div>
                                <div className="row px-3 px-md-0">
                                    {dataob.length ? dataob.map((val, index) => (
                                        <div className="col-12 col-md-4 col-lg-4 text-start" key={index}>
                                            <div className='box-abjective' style={{ backgroundImage: `url(${obj1})` }}>
                                                <div className='topic-abj'>{val.topic}</div>
                                                <div className='font-14'>
                                                    {val.detail}
                                                </div>
                                            </div>
                                        </div>
                                    )) : null}
                                    {/* <div className="col-12 col-md-4 col-lg-4 text-start">
                                        <div className='box-abjective' style={{ backgroundImage: `url(${obj2})` }}>
                                            <div className='topic-abj'>02</div>
                                            <div className='font-14'>
                                                Build the capacities of regional educators to implement effective STEM learning practices through a series of workshops, STEM learning resources, and Career Academies model examples
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-4 text-start">
                                        <div className='box-abjective' style={{ backgroundImage: `url(${obj3})` }}>
                                            <div className='topic-abj'>03</div>
                                            <div className='font-14'>
                                                Foster public-private-academic partnerships and strengthen collaboration to support STEM learning and STEM career awareness in SEA
                                            </div>
                                        </div>
                                    </div> */}

                                </div>
                            </div>
                        </div>
                    </section>
                    <section className='position-relative'>
                        <img src={bg3} className="w-100 d-none d-md-block" />
                        <img src={bg3M} className="w-100 d-block d-md-none" />
                        <div className='position-absolute absolute-center w-100'>
                            <div className="container d-none d-md-block">
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-4 text-start d-md-block d-xl-none">
                                        <div className='text-end'>
                                            <div className='topic-news weight-600 mb-2'>
                                                Participate in our programs
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-8 text-start">
                                        <div className='row'>
                                            <div className="col-12 col-md-3 col-lg-3 text-start">
                                                <div className='box-p'>
                                                    <div className='px-md-3 px-xl-5 py-xl-4'>
                                                        <img src={p1} className="w-100" />
                                                    </div>
                                                    <div className='font-14 mt-2'>
                                                        STEM Project Competition
                                                    </div>
                                                    <div className='mt-3'>
                                                        <Link to="/programmes/stem_project_competition" className="btn-more d-block text-center">LEARN MORE</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-3 col-lg-3 text-start">
                                                <div className='box-p'>
                                                    <div className='px-md-3 px-xl-5 py-xl-4'>
                                                        <img src={p2} className="w-100" />
                                                    </div>
                                                    <div className='font-14 mt-2'>
                                                        Capacity Building of Educators
                                                    </div>
                                                    <div className='mt-3'>
                                                        <Link to="/programmes/capacity_building" className="btn-more d-block text-center">LEARN MORE</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-3 col-lg-3 text-start">
                                                <div className='box-p'>
                                                    <div className='px-md-3 px-xl-5 py-xl-4'>
                                                        <img src={p3} className="w-100" />
                                                    </div>
                                                    <div className='font-14 mt-2'>
                                                        Career Academies Programme Showcase
                                                    </div>
                                                    <div className='mt-3'>
                                                        <Link to="/programmes/career_academies" className="btn-more d-block text-center">LEARN MORE</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-3 col-lg-3 text-start">
                                                <div className='box-p'>
                                                    <div className='px-md-3 px-xl-5 py-xl-4'>
                                                        <img src={p4} className="w-100" />
                                                    </div>
                                                    <div className='font-14 mt-2'>
                                                        STEM Learning Resources
                                                    </div>
                                                    <div className='mt-3'>
                                                        <Link to="/programmes/stem_learning" className="btn-more d-block text-center">LEARN MORE</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-4 text-start d-md-none d-xl-block">
                                        <div className='text-end'>
                                            <div className='topic-news weight-600 mb-5'>
                                                Participate in <br />our programs
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='d-block d-md-none px-4'>
                                <div className='text-end'>
                                    <div className='topic-news weight-600 mb-5'>
                                        Participate in <br />our programs
                                    </div>
                                </div>
                                <div className='banner-custom obj-slide d-block d-md-none'>
                                    <AliceCarousel
                                        mouseTracking
                                        mouseDragEnabled
                                        // infinite
                                        handleDragStart
                                        controlsStrategy="alternate"
                                        disableButtonsControls
                                        disableDotsControls
                                        touchTracking={true}
                                        responsive={responsive}
                                    >
                                        <div className='box-p'>
                                            <div className='px-5 px-md-3 px-xl-5 py-xl-4'>
                                                <img src={p1} className="w-100" />
                                            </div>
                                            <div className='font-14 mt-2'>
                                                STEM Project Competition
                                            </div>
                                            <div className='mt-3'>
                                                <Link to="/programmes/stem_project_competition" className="btn-more d-block text-center">LEARN MORE</Link>
                                            </div>
                                        </div>
                                        <div className='box-p'>
                                            <div className='px-5 px-md-3 px-xl-5 py-xl-4'>
                                                <img src={p2} className="w-100" />
                                            </div>
                                            <div className='font-14 mt-2'>
                                                Capacity Building of Educators
                                            </div>
                                            <div className='mt-3'>
                                                <Link to="/programmes/capacity_building" className="btn-more d-block text-center">LEARN MORE</Link>
                                            </div>
                                        </div>
                                        <div className='box-p'>
                                            <div className='px-5 px-md-3 px-xl-5 py-xl-4'>
                                                <img src={p3} className="w-100" />
                                            </div>
                                            <div className='font-14 mt-2'>
                                                Career Academies Programme Showcase
                                            </div>
                                            <div className='mt-3'>
                                                <Link to="/programmes/career_academies" className="btn-more d-block text-center">LEARN MORE</Link>
                                            </div>
                                        </div>
                                        <div className='box-p'>
                                            <div className='px-5 px-md-3 px-xl-5 py-xl-4'>
                                                <img src={p4} className="w-100" />
                                            </div>
                                            <div className='font-14 mt-2'>
                                                STEM Learning Resources
                                            </div>
                                            <div className='mt-3'>
                                                <Link to="/programmes/stem_learning" className="btn-more d-block text-center">LEARN MORE</Link>
                                            </div>
                                        </div>
                                    </AliceCarousel>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className='position-relative'>
                        <img src={bg4} className="w-100 d-none d-md-block" />
                        <img src={bg4M} className="w-100 d-block d-md-none" />
                        <div className='position-absolute absolute-center w-100'>
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-12 col-md-9 col-lg-8 text-start">
                                        <div className=''>
                                            <div className='box-news-home'>
                                                <div className='weight-600 font-22'>
                                                    {datajoin ? datajoin.topic : null}
                                                </div>
                                                <div className='weight-500 font-14'>
                                                    <div dangerouslySetInnerHTML={{ __html: datajoin ? datajoin.detail : null }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 col-lg-4 text-start">
                                        <div className='text-start text-md-end mt-3 mt-md-0'>
                                            <Link to="/" className="btn btn-default font-14 weight-500" id="button-addon2"
                                                style={{ border: 'unset', borderRadius: "3px", padding: "5px 45px" }}>
                                                Register
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Totop page="home" handleClick={(e) => handleClick(e)} />
                    <Footer />
                </div>

            </div>

        </>
    );
};

export default Home;