import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Modal, Button, Form } from "react-bootstrap";

import Header from '../../layout/header';
import Footer from '../../layout/footer';
import Totop from '../../components/toTop';

// import { Carousel } from 'react-responsive-carousel';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

// import ScrollToTop from "react-scroll-to-top";

import GetService from "../../components/services_member/get.service";

import { Link } from "react-tiger-transition";

import fb from '../../assets/img/Facebook.svg';
import tw from '../../assets/img/Twitter.svg';
import yt from '../../assets/img/Youtube.svg';
import ig from '../../assets/img/Instagram.svg';
import inn from '../../assets/img/In.svg';


const Contact = () => {

    const ref = useRef(null);
    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: "smooth" });
    };

    const [dataconfig, setDataconfig] = useState();

    const fetchData = useCallback(async () => {

        GetService.getFaq().then(
            (response) => {
                if (response.status === 200) {
                    setDataconfig(response.data.config)
                }
            }, (error) => {
                // window.location.reload();
            }
        )
    });

    useEffect(() => {
        fetchData()
    }, []);

    // console.log(dataconfig)

    const [validated, setValidated] = useState(false);
    const handleSubmit = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const data = new FormData(event.target);

        // console.log(data.get('name'));
        // const { email,password,password2 } = event.target.elements;   
        // var errors = [];

        // console.log(errors.push("tel"));

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();

        } else {
            // var val = {
            //     title: data.get('title'),
            //     name: data.get('name'),
            //     last_name: data.get('last_name'),
            //     age: data.get('age'),
            //     gender: data.get('gender'),
            //     social: data.get('social'),
            // };

            // localStorage.setItem('register', JSON.stringify(val));
            // localStorage.setItem("step_1", JSON.stringify(val));
            // setBoxview(2)
            // formRef.current.reset();
        }
        setValidated(true);
    };

    return (
        <>
            <div id="outer-container" ref={ref}>
                <Header page="programmes" />
                <div className="wrapper bg-white box-index">
                    <section className="" id="page-wrap">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 position-relative">

                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12 text-start">
                                                <div className='py-3'>
                                                    <Link to='/' transition='default-fade' className="font-12 a-default">Home</Link>
                                                    <span className='mx-2 font-12'>|</span>
                                                    <span className='font-12'>Contact Us</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {dataconfig ?
                                        <div className="container mt-5">
                                            <div className="row justify-content-center">
                                                <div className="col-12 col-md-6 col-lg-6 text-start pe-md-5">
                                                    <div className='topic-news weight-600'>
                                                        Contact Us
                                                    </div>
                                                    <div className='py-3'>
                                                        {dataconfig.address}
                                                    </div>
                                                    {/* <div className='pt-3'>
                                                        <div className='weight-700'>
                                                            Tel
                                                        </div>
                                                        <div>
                                                            {dataconfig.tel}
                                                        </div>
                                                    </div> */}
                                                    <div className='mt-4'>
                                                        <div className='weight-700'>
                                                            Email
                                                        </div>
                                                        <div>
                                                            {dataconfig.email}
                                                        </div>
                                                    </div>
                                                    <div className='d-block mt-5 pb-5'>
                                                        <a className='px-2 ps-0' href={dataconfig.facebook} target="_blank">
                                                            <img src={fb} style={{ width: "10px" }} />
                                                        </a>
                                                        {/* <a className='px-2' href={dataconfig.twitter} target="_blank">
                                                            <img src={tw} style={{ width: "15px" }} />
                                                        </a> */}
                                                        <a className='px-2' href={dataconfig.youtube} target="_blank">
                                                            <img src={yt} style={{ width: "15px" }} />
                                                        </a>
                                                        {/* <a className='px-2' href={dataconfig.ig} target="_blank">
                                                            <img src={ig} style={{ width: "15px" }} />
                                                        </a>
                                                        <a className='px-2' href={dataconfig.soin} target="_blank">
                                                            <img src={inn} style={{ width: "15px" }} />
                                                        </a> */}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6 text-start">
                                                    <div className='pt-3'>
                                                        <div dangerouslySetInnerHTML={{__html: dataconfig.map}} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : <div className='p-5'>-- No Data --</div>}
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="" id="page-wrap" style={{ backgroundColor: "#F0F0F0" }}>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-12 col-lg-12 text-start">
                                    <div className='weight-600 font-22 pt-5'>
                                        Send Message
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5 pb-5">
                                <div className="col-12 col-md-12 col-lg-12">
                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                        <div className='row text-start'>
                                            <div className='col-12 col-md-6'>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label className='weight-600 font-14'>Name</Form.Label>
                                                    <Form.Control type="text" name='name' placeholder='Your Name' required className='form-control input-custom ps-0 font-12' />
                                                </Form.Group>
                                            </div>
                                            <div className='col-12 col-md-6'>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label className='weight-600 font-14'>Email</Form.Label>
                                                    <Form.Control type="text" name='last_name' placeholder='Your Email' required className='form-control input-custom ps-0 font-12' />
                                                </Form.Group>

                                            </div>
                                            <div className='col-12'>
                                                <Form.Group className="mb-3 text-start" controlId="formBasicEmail">
                                                    <Form.Label className='weight-600 font-14'>Message</Form.Label>
                                                    <Form.Control type="text" name='social' placeholder='Type here' required className='form-control input-custom ps-0 font-12' />
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <div className='w-100 px-4' style={{ bottom: "15px", right: "15px" }}>
                                            <div className='row justify-content-end'>
                                                <div className='col-12 text-end'>
                                                    <span className='a-default py-2 mt-5 cursor-pointer text-end me-3' >
                                                        Cancel
                                                    </span>
                                                    <button className='btn-submit py-1 mt-5 weight-600 px-5' type="submit">
                                                        Next
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </section>

                    <Totop page="programmes" handleClick={(e) => handleClick(e)} />
                    <Footer />
                </div>

            </div >

        </>
    );
};

export default Contact;