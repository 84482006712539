import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Modal, Button, Form } from "react-bootstrap";

import Header from '../../layout/header';
import Footer from '../../layout/footer';
import Totop from '../../components/toTop';

// import { Carousel } from 'react-responsive-carousel';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

// import ScrollToTop from "react-scroll-to-top";

import GetService from "../../components/services_member/get.service";

import { Link } from "react-tiger-transition";

import fb from '../../assets/img/Facebook.svg';
import tw from '../../assets/img/Twitter.svg';
import yt from '../../assets/img/Youtube.svg';
import ig from '../../assets/img/Instagram.svg';
import inn from '../../assets/img/In.svg';


const Organizing = () => {

    const ref = useRef(null);
    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: "smooth" });
    };

    const [dataconfig, setDataconfig] = useState();

    const fetchData = useCallback(async () => {

        GetService.getFaq().then(
            (response) => {
                if (response.status === 200) {
                    setDataconfig(response.data.config)
                }
            }, (error) => {
                // window.location.reload();
            }
        )
    });

    useEffect(() => {
        fetchData()
    }, []);

    // console.log(dataconfig)

    const [validated, setValidated] = useState(false);
    const handleSubmit = (event) => {

        event.preventDefault();

        const form = event.currentTarget;

        const data = new FormData(event.target);

        // console.log(data.get('name'));
        // const { email,password,password2 } = event.target.elements;   
        // var errors = [];

        // console.log(errors.push("tel"));

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();

        } else {
            // var val = {
            //     title: data.get('title'),
            //     name: data.get('name'),
            //     last_name: data.get('last_name'),
            //     age: data.get('age'),
            //     gender: data.get('gender'),
            //     social: data.get('social'),
            // };

            // localStorage.setItem('register', JSON.stringify(val));
            // localStorage.setItem("step_1", JSON.stringify(val));
            // setBoxview(2)
            // formRef.current.reset();
        }
        setValidated(true);
    };

    return (
        <>
            <div id="outer-container" ref={ref}>
                <Header page="programmes" />
                <div className="wrapper bg-white box-index">
                    <section className="" id="page-wrap">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 position-relative">

                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12 text-start">
                                                <div className='py-3'>
                                                    <Link to='/' transition='default-fade' className="font-12 a-default">Home</Link>
                                                    <span className='mx-2 font-12'>|</span>
                                                    <span className='font-12'>Organizing Committee for SEA STEM Fair & Expo 2023</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container mt-5">
                                        <div className="row justify-content-center">
                                            <div className="col-12 col-md-6 col-lg-6 text-start pe-md-5">
                                                <div className='mt-4'>
                                                    <div className='weight-700 mb-3 font-20'>
                                                        Organizing Committee for SEA STEM Fair & Expo 2023
                                                    </div>
                                                    <div className=''>
                                                        <div className='weight-600'>Advisor</div>
                                                        <ul>
                                                            <li>Dr. Kritsachai Somsaman, Centre Director, SEAMEO STEM-ED</li>
                                                        </ul>
                                                    </div>
                                                    <div className=''>
                                                        <div className='weight-600'>Chairperson</div>
                                                        <ul>
                                                            <li>Dr. Edward M. Reeve (Thailand) Senior STEM Expert, SEAMEO STEM-ED
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className=''>
                                                        <div className='weight-600'>Vice-Chairperson</div>
                                                        <ul>
                                                            <li>Dr. Kessara Amornvuthivorn (Thailand)
                                                                Program Director, SEAMEO STEM-ED
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className=''>
                                                        <div className='weight-600'>Committee</div>
                                                        <ul>
                                                            <li>Pg Hajah Mas Joliwane binti Pg Haji Tejuddin (Brunei) Head of Science, Technology and Environment Partnership (STEP) Centre</li>
                                                            <li>Prof. Dato Dr. Noraini Idris (Malaysia) President, National STEM Association (NSA)</li>
                                                            <li>Dr. Norazla binti Mustafa (Malaysia) Principal Assistant Director, Educational Policy Planning and Research Division Ministry of Education Malaysia</li>
                                                            <li>Assoc. Prof. Dr. Frederick T. Talaue (Philippines) Department of Science Education, Brother Andrew Gonzalez FSC College of Education (BAGCED) De La Salle University
                                                            </li>
                                                            <li>Professor Dr. Sheryl Lyn C. Monterola (Philippines) Director, National Institute for Science and Mathematics Education Development (NISMED) Division of Curriculum and Instruction, College of Education University of the Philippines - Diliman
                                                            </li>
                                                            <li>Assoc. Prof. Dr. Lim Tit Meng (Singapore) Chief Executive, Science Centre Singapore, Biological Sciences, National University of Singapore President, Singapore Association for the Advancement of Science (SAAS) President, Singapore National Academy of Science (SNAS)
                                                            </li>
                                                            <li>Dr. Tao Quang Bang (Vietnam) Head of Department of Science, Technology and International Cooperation Lecturer, Faculty of Mechanical Engineering University of Science and Technology | The University of Danang
                                                            </li>
                                                            <li>Dr. Mark Windale (Thailand) Senior STEM Expert, SEAMEO STEM-ED</li>
                                                            <li>Dr. John Robert Stiles (Thailand) Senior STEM Expert, SEAMEO STEM-ED</li>
                                                            <li>Ms. Yaowalak Jittakoat Research Manager, SEAMEO STEM-ED</li>
                                                        </ul>
                                                    </div>
                                                    <div className='mb-5 pb-5'>
                                                        <div className='weight-600'>Secretariat, SEAMEO STEM-ED</div>
                                                        <ul>
                                                            <li>Asst. Prof. Dr. Burin Asavapibhop</li>
                                                            <li>Dr. Orawan Sriboonruang </li>
                                                            <li>Ms. Varangkana Ruengvisesh</li>
                                                            <li>Mr. Nitipol Kiravanich</li>
                                                            <li>Mr. Sarit Sanguanwongse </li>
                                                            <li>Mr. Nuntawut Pimpang</li>
                                                        </ul>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <Totop page="programmes" handleClick={(e) => handleClick(e)} />
                    <Footer />
                </div>

            </div >

        </>
    );
};

export default Organizing;