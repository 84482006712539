// export default function authHeader() {

    const authHeaderMember = () => {

      const user = JSON.parse(localStorage.getItem("member"));

      if (user && user.accessToken) {
        return { Authorization: 'Bearer ' + user.accessToken };
        // return { "x-auth-token": user.accessToken };
      } else {
        return {};
      }
    };
    
    const authHeader = {
      authHeaderMember
    };

    export default authHeader;
// }