import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Form, InputGroup, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import Moment from 'moment';
import Header from '../../layout/header';
import Footer from '../../layout/footer';
import Totop from '../../components/toTop';

import { Link } from "react-tiger-transition";
import GetService from "../../components/services_member/get.service";
import sorn from '../../assets/img/sorn.svg';
import search from '../../assets/img/search.svg';

import { TagsInput } from "react-tag-input-component";

const Learningdetail = () => {

    const member = JSON.parse(localStorage.getItem("member_data"));

    // const user = JSON.parse(localStorage.getItem("member"));

    if (!member) {
        window.location.href = "/programmes/stem_learning";
        // console.log('no login')
    }

    const { id } = useParams();

    const ref = useRef(null);
    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: "smooth" });
    };
    // console.log(ref)

    const [datapage, setDatapage] = useState();
    const [datapageOne, setDatapageOne] = useState();
    const [dataconfig, setDataconfig] = useState();

    const fetchData = useCallback(async () => {

        GetService.getLearning().then(
            (response) => {
                if (response.status === 200) {
                    setDatapage(response.data.data)
                    setDatapageOne((response.data.data).filter(valb => valb.id === `${id}`)[0])
                    setDataconfig(response.data.config)
                }
            }, (error) => {
                // window.location.reload();
            }
        )
    });
    useEffect(() => {
        fetchData()
    }, []);

    // console.log(datapageOne)
    const [selected, setSelected] = useState([]);
    const [readmore, setReadmore] = useState();
    const handleReadmore = (e) => {
        setReadmore(e)
    }

    return (
        <>

            <div id="outer-container" ref={ref}>
                <Header page="programmes" />
                <div className="wrapper bg-white box-index">
                    <section className="" id="page-wrap">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 position-relative">

                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12 text-start">
                                                <div className='py-3'>
                                                    <Link to='/' transition='default-fade' className="font-12 a-default">Home</Link>
                                                    <span className='mx-2 font-12'>|</span>
                                                    <Link to='/programmes/stem_learning' transition='default-fade' className="font-12 a-default">STEM Learning Resources</Link>
                                                    <span className='mx-2 font-12'>|</span>
                                                    <span className='font-12'>{datapageOne ? datapageOne.topic : null}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="position-relative" id="page-wrap" style={{ marginTop: "100px" }}>
                        <div style={{ height: "200px", background: "#fff" }}></div>
                        <div className="container" style={{ marginTop: "-200px" }}>
                            <div className="row mt-4">
                                <div className="col-12 col-md-5 col-lg-5 text-start mb-5 pe-md-5" >
                                    <div className='pe-md-5'>
                                        <div className='topic weight-700 font-22 mb-3'>
                                            Search
                                        </div>
                                        <div className='custom-tag position-relative mb-5'>
                                            <TagsInput
                                                value={selected}
                                                onChange={setSelected}
                                                name="fruits"
                                                placeHolder="Search Tag…"
                                            />
                                            <Button className="btn btn-default position-absolute" id="button-addon2 p-0 ms-3"
                                                style={{ border: 'unset', background: "#000", borderRadius: "3px", padding: "0px 5px", bottom: "0", right: "0" }}>
                                                <img src={search} alt="" className="" style={{ width: "17px" }} />
                                            </Button>
                                        </div>
                                        <div className='topic weight-700 font-22 mb-3'>
                                            Categories
                                        </div>
                                        {datapage ? datapage.map((val, index) => (

                                            <a href={"/programmes/learning_detail/" + val.id} className={val.id === id ? "box-cat d-block active" : "box-cat d-block"} key={index}>
                                                <div className='font-14'>
                                                    {/* {val.title} */}
                                                    <div className='float-end'>
                                                        <img src={sorn} className="" style={{ width: "7px" }} />
                                                    </div>
                                                </div>
                                                <div className='weight-600 font-22 '>
                                                    {val.topic}
                                                </div>
                                                <div className='weight-300 font-12'>
                                                    {val.detail}
                                                </div>
                                            </a>

                                        )) : <div className='col-12 p-5'>-- No Data --</div>}
                                    </div>
                                </div>
                                <div className="col-12 col-md-7 col-lg-7 text-start mb-5" >
                                    {datapageOne ? datapageOne.unit.length ? datapageOne.unit.map((val, index) => (<>
                                        <div className='mb-3 pb-3 box-unit'>
                                            <Link to={"/programmes/unit_detail/" + val.id} transition='default-fade' className={readmore === index ? 'd-block px-3 py-3 text-unit h-box active' : 'd-block px-3 py-3 text-unit h-box'} key={index}>
                                                <div className='weight-700 mb-2'>{val.topic}</div>
                                                <div className='weight-400 font-14'><div dangerouslySetInnerHTML={{ __html: val.detail }} /></div>
                                                <div className='weight-700 mt-2'>source</div>
                                                <div className='weight-400 font-14'>{val.source}</div>
                                            </Link>
                                            {readmore !== index ?
                                            <div onClick={(e) => handleReadmore(index)} className='readmore px-3 pt-2 font-14 cursor-pointer'>Read more</div>
                                            : null}
                                        </div>
                                        

                                    </>)) : <div className='col-12 p-5 text-center'>-- No Data --</div> : <div className='col-12 p-5 text-center'>-- No Data --</div>}
                                </div>
                            </div>

                        </div>
                    </section>


                    <Totop page="programmes" handleClick={(e) => handleClick(e)} />
                    <Footer />
                </div>

            </div >

        </>
    );
};

export default Learningdetail;