import React, { useState, useEffect, useRef, useCallback } from 'react';

import Header from '../../layout/header';
import Footer from '../../layout/footer';
import Totop from '../../components/toTop';

import { Link } from "react-tiger-transition";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import GetService from "../../components/services_member/get.service";

import Modalgallery from "../../components/modalGallery";

import topic from '../../assets/img/topic.svg';
import sorn from '../../assets/img/sorn.svg';
import banner from '../../assets/img/banner-career.jpg';
import bg from '../../assets/img/bg-career.jpg';

const Careeracademies = () => {

    const [modalgallery, setModalgallery] = useState({ view: false });

    const ref = useRef(null);
    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: "smooth" });
    };
    // console.log(ref)
    const [datapage, setDatapage] = useState();
    const [dataconfig, setDataconfig] = useState();
    const [datasetting, setDatasetting] = useState();

    const fetchData = useCallback(async () => {

        GetService.getCareer().then(
            (response) => {
                if (response.status === 200) {
                    setDatapage(response.data.data)
                    setDataconfig(response.data.config)
                }
            }, (error) => {
                // window.location.reload();
            }
        )

        GetService.getPagesetting(3).then(
            (response) => {
                if (response.status === 200) {
                    setDatasetting(response.data.data)
                }
            }, (error) => {
                // window.location.reload();
            }
        )
    });

    useEffect(() => {
        fetchData()
    }, []);

    return (
        <>
            <div id="outer-container" ref={ref}>
                <Header page="programmes" />
                <div className="wrapper bg-white box-index bg-top-center" style={{ backgroundImage: `url(${bg})` }}>
                    <section className="" id="page-wrap">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 position-relative">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12 text-start">
                                                <div className='py-3'>
                                                    <Link to='/' transition='default-fade' className="font-12 a-default">Home</Link>
                                                    <span className='mx-2 font-12'>|</span>
                                                    <span className='font-12'>{datasetting ? datasetting.topic : null}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='py-5'>
                                        <div>
                                            <img src={topic} className="me-3 mb-2" style={{ width: "10px", transform: "rotate(90deg)" }} />
                                        </div>
                                        <div className='weight-600 font-22'>
                                            {datasetting ? datasetting.topic : null}
                                        </div>
                                        <div>{datasetting ? datasetting.sub_title : null}</div>
                                    </div>
                                    <div className="w-100 position-relative px-3 px-md-5">
                                        {datasetting ? <>
                                            <img src={process.env.REACT_APP_BASE_URL_IMG + '/uploads/pages/' + datasetting.id + "/" + datasetting.banner} className="w-100 px-lg-5 d-none d-md-block" />
                                            <img src={process.env.REACT_APP_BASE_URL_IMG + '/uploads/pages/' + datasetting.id + "/" + datasetting.banner_m} className="w-100 px-lg-5 d-block d-md-none" />
                                        </> : null}
                                        {/* <img src={banner} className="w-100 px-lg-5" /> */}
                                        <div className='position-absolute absolute-bottom-career d-none d-md-block'>
                                            <div dangerouslySetInnerHTML={{ __html: datasetting ? datasetting.des : null }} />
                                            <div className='mt-5'>
                                                <Link to="/" className="btn-apply-border d-inline-block">Register</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="position-relative" id="page-wrap" >
                        {datapage ? datapage.length ? datapage.map((val, index) => (
                            <div key={index} className="container my-5 py-3">
                                <div className="row py-md-5 my-md-5">
                                    <div className="col-12 col-md-6 col-lg-6 text-start">
                                        <div className='position-relative px-md-0 px-lg-5'>
                                            <div className='banner-custom banner-custom-2'>
                                                <AliceCarousel mouseTracking
                                                    touchTracking={false}
                                                    infinite
                                                    handleDragStart
                                                    disableDotsControls
                                                    controlsStrategy="alternate"
                                                    renderPrevButton={() => {
                                                        return <p className="position-absolute box-absolute-center">
                                                            <img src={sorn} className="sorn-left" />
                                                        </p>
                                                    }}
                                                    renderNextButton={() => {
                                                        return <p className="position-absolute box-absolute-center">
                                                            <img src={sorn} className="sorn-right" />
                                                        </p>
                                                    }} >

                                                    {val.img ?
                                                        JSON.parse(val.img).map((vall, indexl) => (
                                                            <div className='position-relative gar-black' key={indexl}>
                                                                <img onClick={() => setModalgallery({ view: true, data: JSON.parse(val.img), active: indexl, id: val.id, folder: "career" })}
                                                                    src={process.env.REACT_APP_BASE_URL_IMG + "/uploads/career/" + val.id + '/' + vall} alt="" className="w-100 cursor-pointer" />
                                                            </div>
                                                        ))
                                                        : null}

                                                </AliceCarousel>
                                            </div>
                                            <div className='px-2 pt-1'>
                                                <div className='row'>
                                                    {val.img ?
                                                        JSON.parse(val.img).slice(0, 4).map((vall, indexl) => (
                                                            <div className='col-3 p-1'>
                                                                <div className='position-relative cursor-pointer' onClick={() => setModalgallery({ view: true, data: JSON.parse(val.img), active: indexl, id: val.id, folder: "career" })}>
                                                                    <img src={process.env.REACT_APP_BASE_URL_IMG + "/uploads/career/" + val.id + '/' + vall} alt="" className="w-100 " />
                                                                    {indexl === 3 ?
                                                                        <div className='box-length'>
                                                                            <span className='box-absolute-center position-absolute font-20 color-white'>+1</span>
                                                                        </div>
                                                                        : null}
                                                                </div>
                                                            </div>
                                                        ))
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6 text-start">
                                        <div className='px-md-4 pt-3 pt-md-0'>
                                            <div className='font-14'>Academy/Program</div>
                                            <div className='weight-700 font-22 '>
                                                {val.topic}
                                            </div>
                                            <div className='py-3 font-14'>
                                                <div className='mb-1'><span className='weight-600'>Location :</span> {val.location}</div>
                                                <div className='mb-1'><span className='weight-600'>Time :</span> {val.time}</div>
                                                <div className=''><span className='weight-600'>Implement & Partner :</span> {val.partner}</div>
                                            </div>
                                            <hr />
                                            <div className='pt-2 font-14'>
                                                <div dangerouslySetInnerHTML={{ __html: val.detail }} />
                                            </div>
                                            <div className='pb-5 pt-5'>
                                                <div className='weight-600'>Contact :</div>

                                                {val.contact ? JSON.parse(val.contact).map((valx, indexx) => (
                                                    <div className='mt-2' key={indexx}>
                                                        <a href={valx.url} className='btn-contact' target="_blank">
                                                            {valx.topic} <img src={sorn} className="float-end mt-1" style={{ width: "7px" }} />
                                                        </a>
                                                    </div>
                                                )) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )) : <div className='p-5 my-5'>-- No Data --</div> : <div className='p-5 my-5'>-- No Data --</div>}

                    </section>


                    <Totop page="programmes" handleClick={(e) => handleClick(e)} />
                    <Footer />
                </div>

            </div >

            {modalgallery.view ?
                <Modalgallery show={modalgallery.view} onHide={() => setModalgallery({ view: false })} gallery={modalgallery.data} active={modalgallery.active} folder={modalgallery.folder} id={modalgallery.id} />
                : null}

        </>
    );
};

export default Careeracademies;