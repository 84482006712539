import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Accordion } from 'react-bootstrap';

import Header from '../layout/header';
import Footer from '../layout/footer';
import Totop from '../components/toTop';

import { Link } from "react-tiger-transition";
import GetService from "../components/services_member/get.service";

import bg from '../assets/img/bg-partners.jpg';

const Partners = () => {

    const ref = useRef(null);
    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: "smooth" });
    };

    const [datapage, setDatapage] = useState();
    const [dataconfig, setDataconfig] = useState();
    const [dataPt, setDataPt] = useState();

    const fetchData = useCallback(async () => {

        GetService.getPartners().then(
            (response) => {
                if (response.status === 200) {
                    setDatapage(response.data.data)
                    setDataconfig(response.data.config)
                    setDataPt(response.data.partner)
                }
            }, (error) => {
                // window.location.reload();
            }
        )
    });

    useEffect(() => {
        fetchData()
    }, []);

    return (
        <>
            <div id="outer-container" ref={ref}>
                <Header page="programmes" />
                <div className="wrapper bg-white box-index">
                    <section className="bg-top-center" id="page-wrap" style={{ backgroundImage: `url(${bg})` }}>
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 position-relative">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12 text-start">
                                                <div className='py-3'>
                                                    <Link to='/' transition='default-fade' className="font-12 a-default">Home</Link>
                                                    <span className='mx-2 font-12'>|</span>
                                                    <span className='font-12'>Partners</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {datapage ? datapage.length ?
                                        <div className="container mt-5 mb-5 pb-5">
                                            <div className='topic-news weight-600 text-start mb-5'>
                                                Sponsors
                                            </div>
                                            {datapage.map((val, index) => (
                                                <div className="row align-items-center border-bottom py-5" key={index}>
                                                    <div className="col-12 col-md-3 col-lg-3 text-start">
                                                        <img src={process.env.REACT_APP_BASE_URL_IMG + '/uploads/partners/' + val.id + "/" + val.img} className="w-100 p-4" />
                                                    </div>
                                                    <div className="col-12 col-md-9 col-lg-9 text-start">
                                                        <div className='weight-700 font-18 mb-2'>{val.topic}</div>
                                                        <div>
                                                            {val.detail}
                                                        </div>
                                                        <div className='mt-4'>
                                                            <a href={val.link_web} className='btn-border-blue' target="_blank">Go to Website</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        : null : null}

                                    <div className="container mt-5 mb-5 pb-5">
                                        <div className='topic-news weight-600 text-start '>
                                            Implementing Partners
                                        </div>
                                        {dataPt ?
                                            <div className="row align-items-center border-bottom py-5" >
                                                <div className="col-12 col-md-12 col-lg-12 text-start">
                                                    <div dangerouslySetInnerHTML={{ __html: dataPt.detail }} />
                                                </div>

                                            </div>
                                            : <div className='p-5'>-- No Data --</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Totop page="programmes" handleClick={(e) => handleClick(e)} />
                    <Footer />
                </div>

            </div >

        </>
    );
};

export default Partners;